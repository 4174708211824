import Vue from "vue";
import VueRouter from "vue-router";

// import Open from "../views/Open.vue";
import Dashboard from "../components/Dashboard.vue";
import MarketDashboard from "../components/MarketDashboard.vue";
import History from "../components/History.vue";
import FundHistory from "../components/FundHistory.vue";
import BuySell from "../components/BuySell.vue";
import SetupProfile from "../components/modals/SetupProfile.vue";
import Kyc from "../components/Kyc.vue";
import NotFound from "../views/NotFound.vue"
import Individual from '../components/Individual.vue';
import Login from '../components/modals/Login.vue';
import Signup from '../components/modals/Signup.vue';
import Verification from "../components/Verification.vue";
import MobileActivation from "../views/MobileActivation.vue";
import EmailActivation from "../views/EmailActivation.vue";

Vue.use(VueRouter);

const routes = [

  {
    path: "/authz/:authorization",
    name: "HandleLogin",
    component: Dashboard,
    props: true,
  },
  {
    path: "/confirmemail/:utoken",
    name: "EmailActivation",
    component: EmailActivation,
    props: true,
  },
  {
    path: "/confirmmphone/:utoken",
    name: "MobileActivation",
    component: MobileActivation,
    props: true,
  },
  {
    path: '/individual-setup-profile/:step',
    name: "Individual",
    component: Individual,
    props: true
  },
  {
    path: '/corporate-setup-profile/:step',
    name: 'CorporateSetup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/components/CorporateSetup')
    }
  },
 
  {
    path: '/upload-document',
    name: 'UploadDocument',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "upload-document" */ '@/components/UploadDocument')
    }
  },
  {
    path: '/individual-associates',
    name: 'BoardOfDirectors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "individual-associates" */ '@/components/BoardOfDirectors')
    }
  },
  {
    path: '/corporate/:step',
    name: 'Corporate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate" */ '@/components/Corporate')
    }
  },
  // {
  //   path: "/:pair",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   props: true,
  // },
  //  {
  //   path: "/",
  //   name: "Open",
  //   component: Open,
  //   props: true,
  // },
  {
    path: "/",
    name: "MarketDashboard",
    component: MarketDashboard,
    // props: true,
  },
  {
    path: "/:countryCode",
    name: "MarketDashboardWithCountry",
    component: MarketDashboard,
    props: true,
  },
  
  {
    path: "/:countryCode/login",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/:countryCode/signup",
    name: "Signup",
    component: Signup,
    props: true,
  },
  {
    path: "/setup-profile",
    name: "SetupProfile",
    component: SetupProfile,
    props: true,
  },
  {
    path: "/profile/kyc",
    name: "Kyc",
    component: Kyc,
    props: true,
  },
  {
    path: "/verification",
    name: "Verification",
    component: Verification,
    props: true,
  },

 
  {
    path: "/market/:pair",
    name: "MarketDashboard",
    component: MarketDashboard,
    props: true,
    },

  {
    path: "/trade/history",
    name: "History",
    component: History,
    props: true,
  },

  {
    path: "/fund/history",
    name: "FundHistory",
    component: FundHistory,
    props: true,
  },

  {
    path: "/buy-sell/:pair",
    name: "BuySell",
    component: BuySell,
    props: true,
  },
  {
    path: '*',
    component: NotFound
  }
 

];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;