<template>
  <div  class="main-container">  
    <div class="bg"></div>
     <ViewHeader></ViewHeader>


    <div class="main-body">
      <div><button class="btn btn-secondary" @click="goToDefault"> go back</button></div>

    <div class="transaction-content"> 
          <div class="history-header"> 
            <span>HISTORY</span>
          </div>
          
          <div class="history-content">
            <table>
              <tr> 
                <th>Transaction Date</th>
                
                <th>Ref #</th>
                <th>Description</th>
                <th>Transaction Type</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Currency</th>
                <th>Status</th>
              </tr>
              <tr v-if="init.fundHistory.length > 0" v-for="l in init.fundHistory">
                <td> {{ formatDate(l.activityDate) }} </td>
                <td> {{ l.txnRef }} </td>
                <td> {{ l.activityDesc }} </td>
                <td> {{ l.txnType }} </td>
                <td> {{ l.amountType === 'DR' ?  formatAmount(l.amount) : '' }} </td>
                <td> {{ l.amountType === 'CR' ? formatAmount(l.amount) : '' }} </td>
                <td> {{ l.currency }} </td>
                <td> {{ l.txnStatus }} </td>
              </tr>
              
              <tr v-if="init.fundHistory.length == 0" >
                <td colspan="7" class="text-center"> <h3>No Data Found</h3> </td>
              </tr>
            </table>
        
          </div> 
    </div>
    </div>
    
      </div>

</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral'  
import ViewHeader from '../views/viewHeader.vue'


  export default {
    name: 'history',
    components: {
    ViewHeader
},
    data: function() {
      return  {
        showLoading: true, 
        state: '11'
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },

    },
    methods: {
        changeState(state) {
            this.state = state;

            
        },
        getHistory: function(){

          let date = new Date();

        let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
 
        const params = {
         fromDate: parseFloat(date.getMonth() + 1) + '/01/' + date.getFullYear(),
         // fromDate: '01/01/2022',
          toDate: parseFloat(date.getMonth() + 1) + '/'+ lastDayOfMonth.getDate() +'/' + date.getFullYear()
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');



        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/fundactivities/daterange/all',
        };

        this.init.fundHistory = [];

        this.axios(options)
          .then((response) => {
              this.init.fundHistory = response.data.rowsList
          })
      },
      
      goToDefault() {
        this.$router.push({name: 'MarketDashboard', params: {pair: 'BTCUSDT'}})
      },

    openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      openLogin(){
        this.showLoginModal = !this.showLoginModal;
        this.showSignupModal = false;
      },
      openSignup(){
        this.showSignupModal = !this.showSignupModal;
        this.showLoginModal = false;
      },

      formatDate: function(time){
        return new Date(time).toLocaleString();
      },
      formatAmount:  function (num) {

        if(isNaN(num)) {
            num = 0;
        }
        return parseFloat(num).toFixed(8)
      },



 
      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.init.hasSession = true
              setTimeout( () => {
                this.showLoading = false;
              },1000)

            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                this.showLoading = false;
                this.openLogin();
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
          this.$bvModal.show('logout-modal'); 
          console.log('.')
      },
    },

    beforeMount(){

    },

    mounted() {
        this.checkSession();  
        this.getHistory();
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #FFF;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #141414;
}
#loading .content {
  display: inline-block;
  text-align: center;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
}

.fa-icon { 
    color: #FFF;
    fill: #fff;
}
  .z-index-2 {
    z-index: 2;
  }

  .main-container {
    font-family: 'Medium';
    height:100%;
  }
  .main-body {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
  }
  .bg {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }
  table {
    font-family: 'Medium';
    border-collapse: collapse;
    width: 100%;
    color: #333;
  }
   .hash {
    white-space: nowrap !important;
    width: 20em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  td, th {
   
    text-align: left;
    padding: 1em;
    font-size: 0.75em;
  }

  td {  
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    color: white;
    border-bottom: 1px solid #FFF;
    padding: 1.5em;
  
  }

  th {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    color: #FFF;
  }

  th:first-child { 
    border-top-left-radius: 6px;
  }
  th:last-child { 
    border-top-right-radius: 6px;
  }

  .history-content {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius: 6px;
        overflow-x:auto;
  }
    


  .history-container {  
      padding: 1.5em;
      border-radius: 6px;
      flex-direction: column;
      position: relative;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      padding-top: 5em;
      color: #000;
      background-color: #de8413;
      background-image: linear-gradient(#de8413, #d8962c);
      width: 100%;
      margin: 0 2em;
  }
  
  .history-header {  
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;;
  }


  .option-box {

    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border: 1px solid #8c5b13;
    color: #FFF;
    border-radius: 6px; 
    min-height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3em;
    cursor: pointer;
  }
 .content-icon {
      width: 75px;
    }

  .header { 
    
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
  }
  .logout {
    color: #FFF;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1em;
    padding: 0 15em;
  }



    .btn-secondary {
      background:white !important;
      border-color:rgba(72, 57, 28, 0.978) !important;
      color:black !important;
      border-width:2px !important;
      margin-left:5px !important;
    }


    .cancel {
    color:#8585e5;
    background:none;
    border:none;
    font-family: 'Medium';
    width:15%;
    outline:none;
  }

  .cancel:hover {
    color:#8585e5;
  }

  .cancel:focus {
    box-shadow:1px 1px #8585e5;
  }

  .select {
    color:#8585e5;
    background:none;
    border-color:#8585e5;
    border-width:1px;
    font-family: 'Medium';
  }

  .active {
    border:none;
    border-bottom:1px #8585e5 solid;
    font-family: 'Medium';
    width:15%;
  }

  .column {
  float: left;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
   border: 1px solid black;
}
.row {
    margin: 0 -5px;
    display: flex !important;
    color: white !important;
}
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 16px;
  text-align: center;
  background: transparent;
    backdrop-filter:blur(20px);
  box-shadow:0 0 30px rgba(0,0,0,0.3);
  border: 2px solid #8585e5;
  display: flex;
  color: white !important;
  width: auto;
  margin: 10px;
  
}

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
 
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(min-width: 768px) {
    .content {
      padding: 0 3em;
    }

    .transaction-content {
      padding: 3em;
    }

  }

</style>



