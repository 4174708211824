<template>
  <!--july 27-->
  <div>
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <a class="nav-title" href="javascript:;"><img :src="require('../assets/logo.png')" @click="goToHome">   
          <!-- <span class="store-item-icon">
          <b-icon icon="cart" scale="1" shift-h="5" shift-v="5"></b-icon>
        </span> -->
      </a>
       
          <div class="d-flex" v-if="!init.hasSession">
            <div class="control">
              <a class="button">
                <span  @click="goToShop1">SHOP</span>
              </a>
            </div>
            <div class="control">
              <a class="button">
                <span class="active">TRADE</span>
              </a>
            </div>
            <div class="control">
              <a class="button">
                <span @click="goToWallet1" >WALLET</span>
              </a>
            </div>
          </div>
        <div class="d-flex" v-if="!init.hasSession">
          <div class="control">
            <a class="button">
              <span @click="redirect('login')" class="btn btn-login" >{{ loginLabel }}</span>
            </a>
          </div>
          <div class="control">
            <a class="button">
              <span @click="redirect('signup')" class="btn btn-signup">{{ signupLabel }}</span>
            </a>
          </div>
        </div>
        

        

        <div class="btn-group-horizontal web-view" v-if="init.hasSession">
                <div class="header-wrapper">
                  <div class="profile-wrapper">
                    <img src="../assets/images/settings.png" v-on:click="toggleSidebar">
                  </div>
                  <div class="profile-wrapper">
                  
                    <div>
                      <img src="../assets/images/account.png">
                    </div>
        
                    <div class="name-wrapper" >
                      <div class="text-white text-center"> {{ init.profile.fullName }} </div>
                      <div>
                        <button v-if="init.corpDetails.corpInfo == undefined" class="btn"  @click="goToProfile"  :class="[ init.profile.verificationStatus == 'VERIFIED' ? 'verified' : 'unverified' ]" > INDIVIDUAL - {{ init.profile.verificationStatus }} </button>
                        <button v-if="init.corpDetails.corpInfo != undefined" class="btn" @click="goToProfile" :class="[ init.profile.verificationStatus == 'VERIFIED' ? 'verified' : 'unverified' ]" > BUSINESS -  {{ init.profile.verificationStatus }} </button>
                      </div>
        
                    </div>
                  
                  </div>
      
                </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="div">

        </div>
       
          <div class="d-flex" v-if="init.hasSession">
            <div class="control">
              <a class="button">
              </a>
            </div>
            <div class="control">
              <a class="button">
              </a>
            </div>
            <div class="control">
              <a class="button">
              </a>
            </div>
          </div>
      
        

        

        <div class="btn-group-horizontal web-view" v-if="init.hasSession">
                <div class="header-wrapper-services">
                  <div class="control">
                    <a class="button">
                      <span  @click="goToShop">SHOP</span>
                    </a>
                  </div>
                  <div class="control">
                    <a class="button">
                      <span  class="active">TRADE</span>
                    </a>
                  </div>
                  <div class="control">
                    <a class="button">
                      <span @click="goToWallet" >WALLET</span>
                    </a>
                  </div>
                  <!-- <div class="control">
                    <a class="button">
                      <span @click="overview">My PORTFOLIO</span>
                    </a>
                    </div> -->
      
                </div>
        </div>
      </div>
    </nav>
      <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
      <ChangePin @close="openChangePin" v-if="showChangePin"/>
      <TheNavigation @close="toggleSidebar" v-if="showSidebar && init.hasSession"/>
  </div>
</template>


<script>
import ChangePin from "../components/modals/ChangePin.vue";
import LogoutModal from "../components/modals/LogoutModal";
import TheNavigation from "../views/TheNavigation";

export default {
  name: "viewHeader",
  components: {
    ChangePin,
    LogoutModal,
    TheNavigation
  },
  data() {
    return {
      isUserLoggedIn: "account login",
      isUserSignedUp: "create an account",
      loginLabel: "Log in",
      signupLabel: "Sign up",
      showLoginModal: false,
      showSignupModal: false,
      showChangePin: false,
      showLogoutModal: false,
      pathname:'',
      showSidebar: true


    };
  },
  computed: {
        init() {
            return this.$store.state.init;
        },
      },

      methods: {
        toggleSidebar: function(){
          this.showSidebar = !this.showSidebar
        },
        goToWallet(){
          window.location.href="https://wallet.coexstar.com/#/authz/" + localStorage.getItem("authz");
        },
        goToTrade(){
          window.location.href="https://trade.coexstar.com/#/authz/" + localStorage.getItem("authz");
        },
        goToHome(){
          window.location.href="/#/";
        },
        goToShop(){
          window.location.href="https://store.coexstar.com/#/authz/" + localStorage.getItem("authz");
        },
      overview(){
        window.location.href="https://overview.coexstar.com/#/authz/" + localStorage.getItem("authz");
        
      },
      goToWallet1(){
          window.location.href="https://wallet.coexstar.com";
        },
        goToTrade1(){
          window.location.href="https://trade.coexstar.com/#/";
        },
      
        goToShop1(){
          window.location.href="https://store.coexstar.com/#/";
        },

      goToProfile(){
        window.location.href="/#/profile/kyc"
      },
      redirect(route) {
         //this.$router.push({name: route, params: {countryCode: localStorage.getItem('country')}})

         window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + route;
      },
      // loginModal() {
      //   window.location.href="/#/login"
        

      // },
      // signupModal() {
      //   window.location.href="/#/signup"
       
      // },
    goToDefault() {
        this.$router.push({name: 'Dashboard'})
      },
  
     
      openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
    
      goToVerification(){
          window.location.href="/#/verification/"
      },
      openFundHistory() {
        window.location.href = "/#/fund/history/"
      },
      openChangePin() {
        this.showChangePin = !this.showChangePin;
        
      },

      
    

  },
  mounted(){
    console.log(this.$route.path)
    console.log(this.$route.params.countryCode)
   
  }
};
</script>

<style scoped lang="scss">
	.navbar {
		display: flex;
		justify-content: space-between !important;
		background-color: transparent !important;
		width: 100%;
		height: auto;
}
.nav-title {
  padding: 40px;
  color: white;
  text-decoration: none;
  font-family: 'Light';
}
.nav-title  img{
  width: 10rem;
}

.btn-login{
  background: #695536 !important;
    width: 100%;
    color: #FFF !important;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.5em;
    font-family: 'Light';
    border: none;
    border-radius: 14px;

}
.btn-signup{
    background: transparent !important;
    width: 100%;
    color: #FFF !important;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.5em;
    font-family: 'Light';
    border: #695536 1px solid;
    border-radius: 14px;
}
.btn-signup:hover{
    background: #CFA76B !important;
   
}
.btn-login:hover{
    background: #CFA76B !important;
   
}
	.unverified {
		background: #695536 !important;
		color: white !important;
	}
	.verified {
		background: #024301 !important;
    	border: 1px solid linear-gradient(to top, #aa6c00 0%, #f9cb10 100%);
    	font-family: 'Bold';
      color: white !important;
	}
	.logo {
		width: 15em;
	    margin-top: -14px;
	    margin-left: 1em;
	}
	.header{
		display: flex;
		justify-content: space-between !important;
	}

	.header nav {
		display: flex;
		justify-content: space-between !important;
		background: transparent;
	    display: flex;
	    align-items: center;
	    justify-content: end;
		margin: 20px;

	    a {
	    	color: #FFF;
	    }
	}

	.header .hamburger { 
		padding: 7px;
		border-radius: 3px;
	}
	.btn-icon{
		background-color: transparent !important;
		font-size: 0.5rem;
	}
	.btn-icon img{
		width: 2rem !important;
	}

	.header .hamburger.open {
	    /*padding-right: 0;*/
	}
	.header .hamburger.open > span:first-child {
		transform: rotate(45deg);
	}

	.header .hamburger.open > span:nth-child(2) {
		opacity: 0;
	}

	.header .hamburger.open > span:last-child {
		transform: rotate(-45deg);	
	}

	.header .hamburger > span {
		display: block;
	    width: 26px;
	    height: 3px;
	    background: #FFFFFF;
	    transform-origin: 1.75px 1.75px;
	    transition: all 300ms ease-in-out;
	}

	.header .hamburger > span:not(:last-child) {
		margin-bottom: 5px;

	}
 
	.header-wrapper {
		display: flex !important;
    	align-items: center;
		justify-content: end !important;
		float: right !important;
	}
  .header-wrapper-services {
		display: flex !important;
    	align-items: center;
		justify-content: end !important;
		float: right !important;
	}

	.profile-wrapper {
		display: flex;
		align-items: center;

		.btn {
			padding: 0.5em;
			font-size: 17px;
			margin-top: 0.5em;
		}

		img {
			margin-right: 1em;
			width: 50px;
		}
		.btn-second{
				background: #aa6c00;
				color: #FFF;
				cursor: pointer;
				&:hover {
				background: linear-gradient(to top, #f9cb10 0%, #aa6c00 100%);
				}
				img{
					width: 2rem;
				}
			}
		
	}

	@media only screen and (max-width: 675px) {
    .logo {
		width: 10em;
	    margin-top: -14px;
	    margin-left: 1em;
	}
	.header nav {
		
	    justify-content: space-between;
	}
   }
   .control a {
  padding: 10px;
  background: transparent;
  border: solid transparent;
  color: white !important;
  font-weight: lighter;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Light';
  margin: 10px;
}
.control:focus {
  outline: none;
}
.control a .active {
  /* color: #695536 !important; */
  font-weight: bolder !important;
  font-family: 'Bold';
  padding: 10px;
}
.control a:hover {
  font-weight: bolder !important;
  font-family: 'Bold';
}
#forgotPassword {
  float: right;
  margin: 0 20px;
}
.icon{
  width: 1rem;
}

@media screen and (min-width: 280px) and (max-width: 568px) {
  .web{
  display: none;
}
}
@media only screen and (min-width: 275px) and (max-width: 768px) {
    
    .btn-group-horizontal{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      margin: 0 auto;
    }
    .btn-group-horizontal .dropdown{
      width: 100%;
    }
    .container-fluid{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      margin: 0 auto;
    }
    .header-wrapper-services{
      display: flex !important;
      flex-direction: column !important;
    }
    .btn-group-services{
      display: flex !important;
      flex-direction: column !important;
    }
  }
  .btn-second {
    background: transparent;
    width: 100%;
    color: #FFF;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.1em;
    border: none;
    font-family: 'Light';
    border-radius: 3px !important;
  }
  

</style>
