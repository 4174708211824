<template>

   <div class="backdrop">
     <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Deposit {{ selectedCoin }} </h4>

			<div >
				<v-icon name="chevron-left" color="white"  @click="closeModal()" />
			</div>
          </div>
          
        </div>
        <div class="p-4">
          <div class="mx-auto">
			<div class="coin-container" v-if="state =='UNVERIFIED'">
				<div class="coin-list"> 
					<div class="form-group">
					Please complete KYC first to proceed.
					</div>
					<div class="form-group"> 
				<button class="btn btn-main" @click="goToKYC()">OK</button>
					</div>
					
				</div>
			</div>
            
            <div class="coin-container" v-if="state=='init'">
            		<div class="coin-list"> 

            			<div class="coin-details" v-on:click="selectCoin('BTC')">	
            				<img src="@/assets/crypto/BTC.png">
            			<label>BTC</label>
            			</div>
            			
            		</div>

            		<div class="coin-list"> 

            			<div class="coin-details" v-on:click="selectCoin('ETH')">	
            				<img src="@/assets/crypto/ETH.png">
            			<label>ETH</label>
            			</div>
            			
            		</div>

            		<div class="coin-list" v-on:click="selectCoin('USDT')"> 

            			<div class="coin-details">	
            				<img src="@/assets/crypto/USDT.png">
            			<label>USDT</label>
            			</div>
            			
            		</div>

            		<!-- <div class="coin-list"> 

            			<div class="coin-details" v-on:click="selectCoin('BLX')">	
            				<img src="@/assets/crypto/BLX.png">
            			<label>BLX</label>
            			</div>
            			
            		</div> -->

            </div>

            <div v-if="state=='showQr'">

            	<div v-if="qrLoading">

							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
							<circle cx="84" cy="50" r="10" fill="#020b36">
							    <animate attributeName="r" repeatCount="indefinite" dur="0.4629629629629629s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
							    <animate attributeName="fill" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#020b36;#01103d;#001f50;#01103d;#020b36" begin="0s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#020b36">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							</circle><circle cx="50" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							</circle><circle cx="84" cy="50" r="10" fill="#001f50">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							</circle>
							<!-- [ldio] generated by https://loading.io/ --></svg>
            	</div>
            	<div v-if="!qrLoading">
					<label>({{ selectedCoin }} Network)</label>
            		<div class="qr-wrapper">
		              <qrcode v-bind:value="address.addressValue" v-bind:options="{ width: 200 }" class="qrCanvas"></qrcode>
		            </div>
					<p>Send only {{ selectedCoin }} to this deposit address. This address does not support deposit other network aside from Bitcoin Network.</p>

		            <p>Scan the QR Code or click to copy this address : </p>

		            <div class="input-group">
		              <input type="text" class="form-control" id="addressValue" v-model="address.addressValue" readonly>
		              <div class="input-group-btn">
		                <button class="btn btn-modal" type="button" v-on:click="copy">COPY</button>
		                 
		              </div>
		            </div>
            	</div>
            	
            </div>

            <div v-if="state=='manualDeposit'">


            	<div v-if="manualLoading">

							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
							<circle cx="84" cy="50" r="10" fill="#020b36">
							    <animate attributeName="r" repeatCount="indefinite" dur="0.4629629629629629s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
							    <animate attributeName="fill" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#020b36;#01103d;#001f50;#01103d;#020b36" begin="0s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#020b36">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							</circle><circle cx="50" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							</circle><circle cx="84" cy="50" r="10" fill="#001f50">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							</circle>
							<!-- [ldio] generated by https://loading.io/ --></svg>
            	</div>

            	<div v-if="!manualLoading">
					<label v-if="selectedCoin != 'BTC'">(ERC20 Network)</label>
					<label v-if="selectedCoin == 'BTC'">(Bitcoin Network)</label>
            		<div class="qr-wrapper">
		              <qrcode v-bind:value="manualAddress" v-bind:options="{ width: 200 }" class="qrCanvas"></qrcode>
		            </div>
					<p v-if="selectedCoin != 'BTC'">Send only {{ selectedCoin }} to this deposit address. This address does not support deposit other network aside from ERC20 Network. For other networks, please contact deposit@coexstar.ph.</p>
					<p  v-if="selectedCoin == 'BTC'">Send only {{ selectedCoin }} to this deposit address. This address does not support deposit other network aside from Bitcoin Network. For other networks, please contact deposit@coexstar.ph.</p>

		            <p>Scan the QR Code or click to copy this address : </p>
		            	 
		            <div class="alert alert-success" v-if="error.msg != ''">
		            	{{ error.msg }}
		            </div>
		            <div class="input-group">
		              <input type="text" class="form-control" id="manualAddressValue" v-model="manualAddress" readonly>
		              <div class="input-group-btn">
		                <button class="btn btn-modal" type="button" v-on:click="copyManual">COPY</button>
		                 
		              </div>
		            </div>

		            <div class="form-group">
		            	<label>Amount:</label>
		            	<input type="text" class="form-control" v-model="manualDeposit.sourceAmount" name="">
		            	<p class="error"> {{ error.manualDeposit.sourceAmount }} </p>
		            </div>

		            <div class="form-group">
		            	<label>Txn Hash:</label>
		            	<input type="text" class="form-control" v-model="manualDeposit.cryptoRefId" name="">
		            	<p class="error"> {{ error.manualDeposit.cryptoRefId }} </p>
		            </div>

		            <div class="form-group ">
								<button type="submit" class="btn btn-main" v-on:click="submitDeposit">SUBMIT</button>
					</div>
            	</div>
				

            	

            </div>

          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'DepositModal',
          data(){
              return {
              	state: 'init',
              	selectedCoin: "",
              	address: {},
              	manualAddress: {},
              	qrLoading: true,
              	manualLoading: true,
              	manualDeposit: {
              		sourceAmount: "",
              		sourceCurrency: "",
              		cryptoRefId: "",
              	},
				  userStatus:'',
              	error: {
              		msg: "",
              		manualDeposit: {
	              		sourceAmount: "",
	              		sourceCurrency: "",
	              		cryptoRefId: "",
	              	}
              	}
              }
          },
		  computed: {
				init() {
					return this.$store.state.init;
				},
			
			},
          methods:{
			goToKYC(){
				this.closeModal();
				window.location.href="/#/profile/kyc"
			},
			getProfile: function(){
				const options = {
					method: 'GET',
					headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
					url: '/cx/profile/',
				};

				this.axios(options)
					.then((response) => {
						 this.init.user = response.data;
						this.userId = response.data.entityId
						this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
					
						this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
						this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
					
					console.log(response.data.verificationStatus)
					this.userStatus = response.data.verificationStatus
					if (this.userStatus == 'UNVERIFIED'){
						this.state = 'UNVERIFIED'
					}else{
						this.state = 'init'
					}
					})
			},

          	submitDeposit: function(){
          		let hasError = false;

          		if (this.manualDeposit.sourceAmount == "" || this.manualDeposit.sourceAmount == null || this.manualDeposit.sourceAmount == undefined) {
			 
				        this.error.manualDeposit.sourceAmount = "Amount is required";
				        hasError = true;
				      }

				      if (this.manualDeposit.cryptoRefId == "" || this.manualDeposit.cryptoRefId == null || this.manualDeposit.cryptoRefId == undefined) {
			 
				        this.error.manualDeposit.cryptoRefId = "Txn Hash is required";
				        hasError = true;
				      }

				      if(!hasError) {
				      	const options = {
			            method: 'POST',
			            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
			            url: '/cx/txn/cryptoreceive/init',
			          };
			          this.axios(options)
			            .then((response) => {

			            		 let txnControlId = response.data.txnControlId;

			            		 const params = {
					                cryptoRefId: this.manualDeposit.cryptoRefId,
					              
					                sourceAmount: this.manualDeposit.sourceAmount,
					                sourceCurrency: this.selectedCoin,
					                txnControlId: txnControlId,
					              }

					              const data = Object.keys(params)
					                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
					                .join('&');
					          
					              const options = {
					                method: 'POST',
					                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
					                data,
					                url: '/cx/txn/cryptoreceive/submit',
					              };

					              this.axios(options)
						                .then((response) => { 
 
						                  const params = {
						                    txnControlId: txnControlId,
						                  }

						                  const data = Object.keys(params)
						                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
						                    .join('&');
						              
						                  const options = {
						                    method: 'POST',
						                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
						                    data,
						                    url: '/cx/txn/cryptoreceive/confirm',
						                  };

						                  this.axios(options)
						                    .then(() => { 
						                      this.error.msg = "Your Deposit is now submitted successfully."
						                      	this.manualDeposit.cryptoRefId = '';
						                      	this.manualDeposit.sourceAmount = '';
												 
						                      setTimeout(() => {
								                    this.error.msg = '';
													window.location.reload();
								                  }, 5000)
						                    }).catch((err) => {
						                      
						                    })
						                }).catch((err) => {
						                  
						                  this.error.msg = err.response.data.msgText
						                  if(err.response.data.fieldErrors) {
						                    this.error.msg = err.response.data.fieldErrors[0].defaultMessage
						                  }

						                  setTimeout(() => {
						                    this.error.msg = ''
						                  }, 5000)
						                })




			            })
			            .catch((error) => {
			              console.log(error)
			            });

				      }


          	},
          	getCorpAddress: function(){
          		this.manualLoading = true;
		         const options = {
		            method: 'GET',
		            headers: { 
		            	'content-type': 'application/x-www-form-urlencoded',
		            	'blox-authz': localStorage.getItem("tokenId")  },
		            url: '/cx/txn/cryptoreceive/address/' + this.selectedCoin,
		          };

		          this.axios(options)
		            .then((response) => {
										if(response.data) {
											this.manualAddress = response.data;
											this.manualLoading = false;
										
										} 
		              }).catch((err) => {

		              })
				      },

          	getAddress: function(){
          		if(this.selectedCoin != 'BTC' && this.selectedCoin != 'BLX') {
          			return;
          		}
		         const options = {
		            method: 'POST',
		            headers: { 
		            	'content-type': 'application/x-www-form-urlencoded',
		            	'blox-authz': localStorage.getItem("tokenId")
		             },
		            url: '/cx/cryptoaddress/' + this.selectedCoin,
		          };

		          this.axios(options)
		            .then((response) => {
										if(response.data.addressValue) {
											this.address = response.data;
											this.qrLoading = false;
										
										} else {
											setTimeout(() => {
												this.getAddress();
											}, 5000)
										}
		              }).catch((err) => {

		              })
				      },


            closeModal() {
				this.$emit("close");

            	// if(this.state == 'init') {

                // this.$emit("close");
            	// } else {
            	// 	this.state = 'init';
            	// }
            },
            copy: function(){
				      var copy = document.getElementById("addressValue");

				      copy.select();
				      copy.setSelectionRange(0, 99999);

				      document.execCommand("copy"); 
				    },
				    copyManual: function(){
				      var copy = document.getElementById("manualAddressValue");

				      copy.select();
				      copy.setSelectionRange(0, 99999);

				      document.execCommand("copy"); 
				    },
            selectCoin(coin) {
            	this.selectedCoin = coin;
            	switch(coin) {
            			// case "BTC":
            			case "BLX":
            				this.state = "showQr";
            				this.getAddress();
            			break;
            		case "USDT":
					case "BTC":
            		case "ETH":
            				this.state = "manualDeposit";
            				this.getCorpAddress();
            			break;
            	}
            },
			checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
				url: '/cx/sessioncheck',
				};

				this.axios(options)
				.then((response) => { 
					this.getProfile();
					this.init.hasSession = true
					
					}).catch((err) => {
						this.init.hasSession = false;
					if(err.response.data.msgCode == '00001') {
						
						window.location.href="/#/"
					}  

					if(err.response.data.msgCode == '00020') {

						setTimeout( () => {
						window.location.href = "/#/setup-profile";
					},1000)
						
					}  

					})
				},
          },
		  beforeMount(){
			  this.checkSession();
		  },
          watch: {
          	'manualDeposit.sourceAmount': function(newValue, oldValue) {
     
			          if(newValue !== oldValue) { 
			          this.error.manualDeposit.sourceAmount = "";  
			           }
			      },
			      'manualDeposit.cryptoRefId': function(newValue, oldValue) {
     
			          if(newValue !== oldValue) {   
			          	this.error.manualDeposit.cryptoRefId = "";  
			           }
			      },
          }

      }

  </script>
  <style scoped lang="scss">
.qr-wrapper {
    display:flex;
    align-items: center;
    justify-content:center;
  }
.coin-container {
  		display: flex;
  		align-items: center;
    justify-content: space-around;
  		 .coin-list {
  		 	.coin-details {
  		 		display: flex;
  		 		align-items: center;
  		 		justify-content: center;
  		 		flex-direction: column;
  		 		padding: 0.6em;
  		 		cursor: pointer;
  		 		border-radius: 6px;

  		 		&:hover {
  		 			background: #CCC;
  		 		}

  		 		img {
	  		 		width: 50px;
	  		 	}
	  		 	label {
	  		 		font-size: 1em;
					font-family:'Light';
	  		 	}
  		 	}
  		 	
  		 }
  	}

  .b-modal{
    width: 400px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2; 
	max-height: calc(100vh - 110px);
    overflow-y: auto;
	font-family: 'Bold';
  }
  .error {
  	color: red;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
	  font-family: 'Bold';
  }
.modal-title{
        margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
	font-family: 'Bold';
    svg {
    	fill: #FFF;
    	cursor: pointer;
    }
  }
.modal-title img{
    width: 4rem;
  }

  h4 {
  	margin: 0;
  }
  
  .btn-send {
       background-image: linear-gradient(to right, #361c56, #452469);
    margin-top: 1em;
    width: 100%;
    color: #9999ff;
    padding: 10px;
    border-radius: 10px;
 }

.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  } 

   @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }
	label{
		color: black;
	}
  </style>