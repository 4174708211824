<template>

   <div class="backdrop">
     <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Withdraw from Funding Account {{ selectedCoin }} </h4>

            <div >
				<v-icon name="chevron-left" color="white"  @click="closeModal()" />
			</div>
          </div>
          
        </div>

		<!-- <div>
			<div>
				<div class="coin-container" v-if="state=='UNVERIFIED'">
					<h6>Verify your account to use this feature.</h6>
				</div>
			</div>
		</div> -->
        <div class="p-4">
          <div class="mx-auto">
			<div class="coin-container" v-if="state =='UNVERIFIED'">
				<div class="coin-list"> 
					<div class="form-group">
					Please complete KYC first to proceed.
					</div>
					<div class="form-group"> 
				<button class="btn btn-main" @click="goToKYC()">OK</button>
					</div>
					
				</div>
			</div>

            
            <div class="coin-container" v-if="state=='VERIFIED'">
            		<div class="coin-list"> 

            			<div class="coin-details" v-on:click="selectCoin('BTC')">	
            				<img src="@/assets/crypto/BTC.png">
            			<label>BTC</label>
            			</div>
            			
            		</div>

            		<div class="coin-list"> 

            			<div class="coin-details" v-on:click="selectCoin('ETH')">	
            				<img src="@/assets/crypto/ETH.png">
            			<label>ETH</label>
            			</div>
            			
            		</div>

            		<div class="coin-list" v-on:click="selectCoin('USDT')"> 

            			<div class="coin-details">	
            				<img src="@/assets/crypto/USDT.png">
            			<label>USDT</label>                                                 
            			</div>
            			
            		</div>
					<!-- <div class="coin-list"> 

            			<div class="coin-details" v-on:click="selectCoin('BLX')">	
            				<img src="@/assets/crypto/BLX.png">
            			<label>BLX</label>
            			</div>
            			
            		</div> -->

            </div>
			 
            <div v-if="state=='showQr'">

            	<div v-if="qrLoading">

							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
							<circle cx="84" cy="50" r="10" fill="#020b36">
							    <animate attributeName="r" repeatCount="indefinite" dur="0.4629629629629629s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
							    <animate attributeName="fill" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#020b36;#01103d;#001f50;#01103d;#020b36" begin="0s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#020b36">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							</circle><circle cx="50" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							</circle><circle cx="84" cy="50" r="10" fill="#001f50">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							</circle>
							<!-- [ldio] generated by https://loading.io/ --></svg>
            	</div>
            	
            	
            </div>

            <div v-if="state=='manualWithdraw'">


            	<!-- <div v-if="manualLoading">

							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
							<circle cx="84" cy="50" r="10" fill="#020b36">
							    <animate attributeName="r" repeatCount="indefinite" dur="0.4629629629629629s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
							    <animate attributeName="fill" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#020b36;#01103d;#001f50;#01103d;#020b36" begin="0s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#020b36">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
							</circle><circle cx="50" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"></animate>
							</circle><circle cx="84" cy="50" r="10" fill="#001f50">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"></animate>
							</circle><circle cx="16" cy="50" r="10" fill="#01103d">
							  <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							  <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"></animate>
							</circle> </svg>
            	</div> -->

            	<div >
					<p style="color:red;">{{error.msg}}</p>
            		
		          

		            <div class="form-group">
		            	<label>Amount:</label>
		            	<input type="text"   @keypress="isAmount($event)" class="form-control" v-model="manualWithdraw.sourceAmount" name="">
		            	<p class="error"> {{ error.manualWithdraw.sourceAmount }} </p>
		            </div>

		            <div class="form-group">
		            	<label>Wallet Address:</label>
		            	<input type="text" class="form-control" v-model="manualWithdraw.outboundCryptoAddr" minlength="30" maxlength="66" name="">
		            	<p class="error"> {{ error.manualWithdraw.outboundCryptoAddr }} </p>
		            </div>

		            <div class="form-group ">
						<button type="submit" class="btn btn-main" v-on:click="submitWithdraw">SUBMIT</button>
						<!-- <button type="submit" class="btn btn-cancel" @click="closeModal()" >CANCEL</button> -->
					</div>
            	</div>
            </div>

			<div v-if="state=='callSendApi'">
					<form v-on:submit.prevent="sendBlx">
					
					<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
					
					
						<div class="form-container">
							<label for="receipt">Wallet Address:</label>
						<input type="text" id="receipt" name="receipt" v-model="manualWithdraw.outboundCryptoAddr" class="form-control" >
						<span class="error" v-if="errors.address != ''"> {{ errors.address }} </span> 
						</div>
					
					
					<div class="form-container">
						<label for="ampunt">Amount:</label>
						<input type="text" id="amount" name="amount"  @keypress="isAmount($event)" v-model="manualWithdraw.sourceAmount" class="form-control">
						<span class="error" v-if="errors.amount != ''"> {{ errors.amount }} </span> 
					</div>
					
					
					<div class="flex  margin-top">
						<button  class="btn btn-main" type="submit">  
							<span>Send</span>	
						</button>
						<!-- <button type="submit" class="btn btn-cancel" @click="closeModal()" >CANCEL</button> -->
						
					</div>
			
			
					</form>
				</div>
				<div v-if="state == 'pin'"> 
						<form  v-on:submit.prevent="verifyPin" class="text-center">
							<p class="text-center">Enter your PIN:</p>
							<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
							<div class="d-flex justify-content-center">
								<div class="pin-wrapper" >
									<div>
									<input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
									</div>
						
									<div>
									<input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
									</div>
						
									<div>
									<input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
									</div>
						
									<div>
									<input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
									</div>

									<div>
										<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
									</div>
							
									<div>
										<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
									</div>
						
								</div> 
							</div>
					
							<div class="form-group ">
								<button type="submit" class="btn btn-main">SUBMIT</button>
								<button type="submit" class="btn btn-cancel" @click="closeModal()" >CANCEL</button>
							</div>

						</form>
					</div>
					<div v-if="state == 'otp'"> 
						<form  v-on:submit.prevent="verifyOtp">
					
							<p class="text-center">Enter the one-time PIN you received:</p>
							<div class="alert alert-danger text-center" v-if="systemMsg != ''"> {{ systemMsg }} </div>
							<div class="d-flex justify-content-center">
								<div class="otp-wrapper" >
								<div>
									<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
								</div>
						
								<div>
									<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
								</div>
						
								<div>
									<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
								</div>
						
								<div>
									<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
								</div>
						
								<div>
									<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
								</div>
						
								<div>
									<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
								</div>
								</div>
							</div>
					<div class="text-center">
					<p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
					<p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
							</div>
					
							<div class="form-group text-center">
								<button type="submit" class="btn btn-main">SUBMIT</button>
								<button type="submit" class="btn btn-cancel" @click="closeModal()" >CANCEL</button>
								
							</div>
						</form>
					</div>

          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'WithdrawModal',
          data(){
              return {
				ifResent: true,
				timerInSeconds: 60,
				pinArr: [],
				otpArr: [],
				systemMsg: "",
				showError: false,
				successInfo: {},
              	state: 'VERIFIED',
              	selectedCoin: "",
              	address: {},
              	manualAddress: {},
              	qrLoading: true,
              	manualLoading: true,
              	manualWithdraw: {
              		sourceAmount: "",
              		sourceCurrency: "",
              		outboundCryptoAddr: "",
              	},
				blx:{
					sourceAmount: "",
              		outboundCryptoAddr: "",
					symbol:'BLX',
					privateKey: '',

				},
				userStatus: '',
              	error: {
              		msg: "",
              		manualWithdraw: {
	              		sourceAmount: "",
	              		sourceCurrency: "",
	              		outboundCryptoAddr: "",
	              	}
              	},
				errors:{
					address:'',
					amount: ''
				  }
              }
          },
		  computed: {
				init() {
					return this.$store.state.init;
				},
			
			},
          methods:{
			goToKYC(){
				this.closeModal();
				window.location.href="/#/profile/kyc"
			},
			sendBlx() {
				this.state = 'pin'
			},
			resendCode: function() {
						const options = {
							method: 'POST',
							headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
							url: '/cx/private/resend2fa',
						};

						this.axios(options)
							.then((response ) => { 
							this.systemMsg = ""
							this.showError = false
							this.ifResent = true
							console.log(this.ifResent)
							this.timer();
							}).catch((err) => {
								console.log('resend code errror')
								console.log(err)
							})
						},

			timer() {
					let counterInterval = setInterval(() => {
					this.timerInSeconds--;
					// console.log(this.timerInSeconds)

						if(this.timerInSeconds == 0 || this.ifResent == false) {
									clearInterval(counterInterval)
									this.ifResent = false
									this.timerInSeconds = 60
								}
						}, 1000);
					},

				timeFormat(seconds) {
							let date = new Date(0);
							date.setSeconds(seconds);
							return date.toISOString().substr(14, 5);
						},

				verifyPin() {
							const params = {
								pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
								}

								const data = Object.keys(params)
								.map((key) => `${key}=${encodeURIComponent(params[key])}`)
								.join('&');
						

								const options = {
								method: 'POST',
								headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
								data,
								url: '/cx/private/validatepin',
								};
								this.axios(options)
									.then((response) => {
										this.state = 'otp'
									this.clearError();
									this.timer();
									})
									.catch((error) => {
										// Error code goes here
										this.pinArr = []
										this.showError = true
										this.systemMsg = error.response.data.msgText
										console.log(error.response.data.msgText)
										setTimeout(()=>{
										this.clearError()
										},10000);
									});

						},

				clearError() {
							this.systemMsg = ""
							this.showError = false
						},

				verifyOtp() {
							const params = {
								otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
								}

								const data = Object.keys(params)
								.map((key) => `${key}=${encodeURIComponent(params[key])}`)
								.join('&');
						

								const options = {
								method: 'POST',
								headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
								data,
								url: '/cx/private/validate2fa',
								};
								this.axios(options)
									.then((response) => {
									this.submitWithdraw();
									this.clearError();
									})
									.catch((error) => {
										// Error code goes here
										this.showError = true
										this.otpArr = []
										this.systemMsg = error.response.data.msgText
										window.location.reload();
										console.log(error)
										setTimeout(()=>{
										this.clearError()
									},10000);
								});
						},

				isNumber: function(evt) {
							evt = (evt) ? evt : window.event;
							var charCode = (evt.which) ? evt.which : evt.keyCode;
							if ((charCode > 31  && (charCode < 48 || charCode > 57)) ) {
							evt.preventDefault();
							} else {
							return true;
							}
						},
				isAmount: function(evt) {
							evt = (evt) ? evt : window.event;
							var charCode = (evt.which) ? evt.which : evt.keyCode;
 
							if ((charCode > 31 && charCode != 46  && (charCode < 48 || charCode > 57)) ) {
							evt.preventDefault();
							} else {
							return true;
							}
						},
			otpBack: function(event, currrentBox) {
						if(this.state == 'pin') {
							if (event.keyCode === 8) {
							if (currrentBox != 1) {

								setTimeout(function() { 
									document.getElementById("pin"+ (currrentBox - 1)).focus();
								}, 50)

							}
						}

						if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

							setTimeout(function() { 

								if((currrentBox) < 6) {
									document.getElementById("pin"+ (currrentBox + 1)).focus();
								}
								
							}, 50)

						}
						} else if(this.state == 'otp') {
							if (event.keyCode === 8) {
							if (currrentBox != 1) {

								setTimeout(function() { 
									document.getElementById("otp"+ (currrentBox - 1)).focus();
								}, 50)

							}
						}

						if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

							setTimeout(function() { 

								if((currrentBox) < 6) {
									document.getElementById("otp"+ (currrentBox + 1)).focus();
								}
								
							}, 50)

						}
						}
					},

          	submitWithdraw: function(){
          		let hasError = false;

          		if (this.manualWithdraw.sourceAmount == "" || this.manualWithdraw.sourceAmount == null || this.manualWithdraw.sourceAmount == undefined) {
			 
				        this.error.manualWithdraw.sourceAmount = "Amount is required";
				        hasError = true;
				      }

				      if (this.manualWithdraw.outboundCryptoAddr == "" || this.manualWithdraw.outboundCryptoAddr == null || this.manualWithdraw.outboundCryptoAddr == undefined) {
			 
				        this.error.manualWithdraw.outboundCryptoAddr = "Wallet Address is required";
				        hasError = true;
				      }
					   if (this.manualWithdraw.outboundCryptoAddr.length < 30 || this.manualWithdraw.outboundCryptoAddr.length > 66 ) {
			 
				        this.error.manualWithdraw.outboundCryptoAddr = "Please enter a valid wallet address.";
				        hasError = true;
				      }

				      console.log(hasError)

				      if(!hasError) {
				      	const options = {
			            method: 'POST',
			            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
			            url: '/cx/txn/cryptotransfer/init',
			          };
			          this.axios(options)
			            .then((response) => {

			            		 let txnControlId = response.data.txnControlId;

			            		 let params = {
					                outboundCryptoAddr: this.manualWithdraw.outboundCryptoAddr,
					              	manual: true,
					                sourceAmount: this.manualWithdraw.sourceAmount,
					                sourceCurrency: this.selectedCoin,
					                txnControlId: txnControlId,

					              }

					              if(this.selectedCoin == 'BLX') {
					              	params.manual = false;
					              }

					              const data = Object.keys(params)
					                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
					                .join('&');
					          
					              const options = {
					                method: 'POST',
					                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
					                data,
					                url: '/cx/txn/cryptotransfer/submit',
					              };

					              this.axios(options)
						                .then((response) => { 
 
						                  const params = {
						                    txnControlId: txnControlId,
						                  }

						                  const data = Object.keys(params)
						                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
						                    .join('&');
						              
						                  const options = {
						                    method: 'POST',
						                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
						                    data,
						                    url: ' /cx/txn/cryptotransfer/confirm',
						                  };

						                  this.axios(options)
						                    .then(() => { 
												
						                      this.error.msg = "Your Withdrawal request is now submitted successfully."
						                      this.systemMsg = "Your Withdrawal request is now submitted successfully."
						                      	this.manualWithdraw.outboundCryptoAddr = '';
						                      	this.manualWithdraw.sourceAmount = '';
												  window.location.reload();
						                      setTimeout(() => {
								                    this.error.msg = ''
								                  }, 9000)
						                    }).catch((err) => {
						                      
						                    })
						                }).catch((err) => {
						                 
						                  this.error.msg = err.response.data.msgText
						                  this.systemMsg = err.response.data.msgText
						                  if(err.response.data.fieldErrors) {
						                    this.error.msg = err.response.data.fieldErrors[0].defaultMessage
						                    this.systemMsg = err.response.data.fieldErrors[0].defaultMessage
						                  }
										  if(err.response.data.msgText == 'Unexpected System Error'){
											  this.error.msg = 'Insufficient balance.'
										  }

						                  setTimeout(() => {
						                    this.error.msg = ''
						                    this.systemMsg = ''
						                  }, 5000)
						                })




			            })
			            .catch((error) => {
			              console.log(error)
			            });

				      }


          	},
		
          	getCorpAddress: function(){
          		
		         const options = {
		            method: 'GET',
		            headers: { 
		            	'content-type': 'application/x-www-form-urlencoded',
		            	'blox-authz': localStorage.getItem("tokenId")  },
		            url: '/cx/txn/cryptoreceive/address/' + this.selectedCoin,
		          };

		          this.axios(options)
		            .then((response) => {
										if(response.data) {
											this.manualAddress = response.data;
											this.manualLoading = false;
										
										} 
		              }).catch((err) => {

		              })
				      },

          	getAddress: function(){
          		if(this.selectedCoin != 'BTC') {
          			return;
          		}
		         const options = {
		            method: 'POST',
		            headers: { 
		            	'content-type': 'application/x-www-form-urlencoded',
		            	'blox-authz': localStorage.getItem("tokenId")
		             },
		            url: '/cx/address/' + this.selectedCoin,
		          };

		          this.axios(options)
		            .then((response) => {
										if(response.data.addressValue) {
											this.address = response.data;
											this.qrLoading = false;
										
										} else {
											setTimeout(() => {
												this.getAddress();
											}, 5000)
										}
		              }).catch((err) => {

		              })
				      },


            closeModal() {
            	this.$emit("close");
            },
           
        selectCoin(coin) {
            	this.selectedCoin = coin;
            	switch(coin) {
            			// case "BTC":
            			// 	this.state = "manualWithdraw";
            				
            			// break;
            		// case "USDT":
            		// case "ETH":
					// case "BTC":
            		// 		this.state = "manualWithdraw";
            		// 		this.getCorpAddress();
            		// 	break;
					case "USDT":
            		case "ETH":
					case "BTC":
					case "BLX":
						this.state = "callSendApi";
						this.sendBlx;
            	}
            } ,
		getProfile: function(){
				const options = {
					method: 'GET',
					headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
					url: '/cx/profile/',
				};

				this.axios(options)
					.then((response) => {
						 this.init.user = response.data;
						this.userId = response.data.entityId
						this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
					
						this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
						this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
					
					console.log(response.data.verificationStatus)
					this.userStatus = response.data.verificationStatus
					if (this.userStatus == 'UNVERIFIED'){
						this.state = 'UNVERIFIED'
					}else{
						this.state = 'VERIFIED'
					}
					})
			},
			checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
				url: '/cx/sessioncheck',
				};

				this.axios(options)
				.then((response) => { 
					this.getProfile();
					this.init.hasSession = true
					
					}).catch((err) => {
						this.init.hasSession = false;
					if(err.response.data.msgCode == '00001') {
						
						window.location.href="/#/"
					}  

					if(err.response.data.msgCode == '00020') {

						setTimeout( () => {
						window.location.href = "/#/";
					},1000)
						
					}  

					})
				},
          },
          watch: {
          	'manualWithdraw.sourceAmount': function(newValue, oldValue) {
     
			          if(newValue !== oldValue) { 
			          this.error.manualWithdraw.sourceAmount = "";  
			           }
			      },
			      'manualWithdraw.outboundCryptoAddr': function(newValue, oldValue) {
     
			          if(newValue !== oldValue) {   
			          	this.error.manualWithdraw.outboundCryptoAddr = "";  
			           }
			      },
          },
		  beforeMount(){
			  this.checkSession();
		  }

      }

  </script>
  <style scoped lang="scss">
.qr-wrapper {
    display:flex;
    align-items: center;
    justify-content:center;
  }
  	.coin-container {
  		display: flex;
  		align-items: center;
    justify-content: space-around;
  		 .coin-list {
  		 	.coin-details {
  		 		display: flex;
  		 		align-items: center;
  		 		justify-content: center;
  		 		flex-direction: column;
  		 		padding: 0.6em;
  		 		cursor: pointer;
  		 		border-radius: 6px;
				   font-family: 'Light';
  		 		&:hover {
  		 			background: #CCC;
  		 		}

  		 		img {
	  		 		width: 50px;
	  		 	}
	  		 	label {
	  		 		font-size: 1em;
	  		 	}
  		 	}
  		 	
  		 }
  	}

  .b-modal{
    width: 400px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2; 
	max-height: calc(100vh - 110px);
    overflow-y: auto;
	font-family: 'Bold';
  }
  .error {
  	color: red;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
        margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
	font-family: 'Bold';
    width: 100%;
    svg {
    	fill: #FFF;
    	cursor: pointer;
    }
  }
.modal-title img{
    width: 4rem;
  }

  h4 {
  	margin: 0;
  }
  
  .btn-cancel {
    width: 100%;
    color: #4e4e4f;
    padding: 10px;
    border-radius: 10px;
 }

.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  } 

   @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }

	  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

  

   .error {
  	color: red;
  }
  label{
	color: black
  }

  button {
  	margin-top:1em;
  }

  .btn-send {
   background-color:orange;
   color:white;
   width:100%;
   margin-top:10px;
 }

  .otp-wrapper, .pin-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
	}

  </style>