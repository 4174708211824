<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="mt-2 text-white">View ID {{poidItem.poidKey}}</h5>
            <p @click="closeModal" class="x">x</p>
        </div>
      <div class="modalBody">

        <img :src="poidItem.poidFile" />
        
          
        
   

      </div>
      <div class="form-group" > <button @click.prevent="closeModal()" class="btn btn-main">Close</button>
   
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props:["poidItem"],
    components: {

    },
    data: function() {
        return {
          uploadError:'',
            idType:'selfie',
            description:'withID',
            selectedFile:'',
            selectedFileBack:'',
            idNumber:'111',
            uploadFieldName: 'imageFile',
            uploadFieldNameBack: 'imageFile',
            isSuccess: false,
            bothSuccess: false,
            isFailed: false,
            isLoading: false,
            response:'',
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
            isSubmitting: false,
            poidKey: ''
            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
      uploadNow(){
        this.step = '1'
      },
          handleFileSelect() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFile = file ? file.name : '';
          },
          handleFileSelectBack() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFileBack = file ? file.name : '';
          },

        closeModal(){
            this.$emit("close")
         
        },

        done(){
          this.$emit("close")
        },
        viewId(poidKey) {
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' , 'blox-authz': localStorage.getItem("tokenId") },
                url: '/cx/poids/image/' + this.poidKey,
              };

              this.axios(options)
              .then((response) => {

              });
            }
       
      
    }
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 620px;
    height: auto;
    margin: 50px auto;
    background: rgb(13, 2, 57);
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.modal-header {
  background: rgb(13, 2, 57);
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:rgb(23, 13, 76);
    width:100%;
    padding:50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.buttonWrapper {
    width:100%;
    margin-top: 10px;
    padding-top: 15px;
}

.x {
    cursor:pointer;
}
.red{
    color: red;
}




    @media screen and (max-width: 1100px) {
    
          
    }
   @media screen and (max-width: 800px) {
     
  }

    @media screen and (max-width: 600px) {
       
        
    }

    @media screen and (min-width: 280px) and (max-width: 420px) {

       .b-modal {
            width: 350px;
            height: auto;
            margin-top: 50px;
            background: rgb(13, 2, 57);
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
          background: rgb(13, 2, 57);
            width:100%;
            padding: 20px 10px;
            border-radius: 10px;
        }
        

  
      


    }

    @media screen and (min-width: 480px) and (max-width: 580px) {
        .b-modal {
                width: 405px;
        }

    
     
    }
    .wrapper{
      display: flex;
       padding: 2em;
      justify-content: center !important;
      margin: 0 auto !important;
  }
 
    .product-grid {
      display: flex;
     
    }

  .product-card {
      background-image: linear-gradient(
              180deg,
            #10184d 29%,
            #00103d 59%,
              #010c2a 88%
      );
      box-shadow:0 0 30px rgba(0,0,0,0.3);
      border: 1px solid transparent;
      border-radius: 12px;
      width: 7rem;
      height: auto;
      text-align: center;
      margin: 10px !important;
      color: white;
      cursor: none;
  
  }


  .product-image {
    padding: 10px 0px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0 auto;
      background-color: none !important;
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
</style>