// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/crypto/BLX.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-loader[data-v-9e2b17d2]{display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(2,11,54,.731);z-index:999}.cube1[data-v-9e2b17d2]{width:40px;height:40px;border-radius:50%;margin-right:10px;background:#862f7e}.cube2[data-v-9e2b17d2]{background:#7000a3;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.cube2[data-v-9e2b17d2],.cube3[data-v-9e2b17d2]{width:40px;height:40px;margin-right:10px;border-radius:50%}.cube3[data-v-9e2b17d2]{background:#681f6f;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.cube4[data-v-9e2b17d2]{background:rgba(86,20,102,.5254901960784314);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.cube4[data-v-9e2b17d2],.cube5[data-v-9e2b17d2]{width:40px;height:40px;margin-right:10px;border-radius:50%}.cube5[data-v-9e2b17d2]{background:rgba(22,17,99,.33725490196078434);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.cube[data-v-9e2b17d2]:first-child{-webkit-animation:left-data-v-9e2b17d2 1s infinite;animation:left-data-v-9e2b17d2 1s infinite}.cube[data-v-9e2b17d2]:last-child{-webkit-animation:right-data-v-9e2b17d2 1s .5s infinite;animation:right-data-v-9e2b17d2 1s .5s infinite}@-webkit-keyframes left-data-v-9e2b17d2{40%{transform:translateX(-60px)}50%{transform:translateX(0x)}}@keyframes left-data-v-9e2b17d2{40%{transform:translateX(-60px)}50%{transform:translateX(0x)}}@-webkit-keyframes right-data-v-9e2b17d2{40%{transform:translateX(60px)}50%{transform:translateX(0x)}}@keyframes right-data-v-9e2b17d2{40%{transform:translateX(60px)}50%{transform:translateX(0x)}}", ""]);
// Exports
module.exports = exports;
