<template>

   <div class="backdrop">
     <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">My Profile</h4>

            <div>
				<v-icon name="chevron-left" color="white"  @click="closeModal()" />
			</div>
          </div>
          
        </div>
        <div class="p-4">
          <div class="mx-auto">
            
			  <div class="coin-container" v-if="status=='NONE'">
            		<div class="coin-list"> 
						<div class="form-group">
						Please complete KYC first to proceed.
						</div>
						<div class="form-group"> 
					        <button class="btn btn-send" @click="goToKYC()">Verify Now</button>
						</div>
						
            		</div>
            </div>
            <div class="coin-container" v-if="status=='VERIFIED'">
            		<div class="coin-list"> 
						<div class="form-group">
						Your account is VERIFIED.
						</div>
						<div class="form-group"> 
					        DEPOSIT STATUS: <button class="btn btn-send">UNLIMITED</button>
                            WITHDRAW STATUS: <button class="btn btn-send">UNLIMITED</button>
						</div>
						
            		</div>
            </div>
            

           
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
      props: ['status'],
          name: 'Profile',
          data(){
              return {
              	state: 'UNVERIFIED',
              	
				userStatus: '',
                profile: {},
              	
              }
          },
          methods:{
          goToKYC(){
            window.location.href="/#/profile/kyc"
          },

          	

            closeModal() {
                this.$emit("close");
            	
            },
           
           
			  getProfile: function(){
				const options = {
					method: 'GET',
					headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
					url: '/cx/profile/',
				};

				this.axios(options)
					.then((response) => {
             this.init.user = response.data;
               this.userId = response.data.entityId
               this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                   console.log(this.init.corpDetails.corpInfo)
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
					
					console.log(response.data.verificationStatus)
					this.userStatus = response.data.verificationStatus
                    this.profile = response.data
					if (this.userStatus == 'UNVERIFIED'){
						this.state = 'UNVERIFIED'
					}
                    if (this.userStatus == 'VERIFIED'){
						this.state = 'VERIFIED'
					}

					})
			},
          },
          
		  mounted(){
			  this.getProfile();
		  }

      }

  </script>
  <style scoped lang="scss">
.qr-wrapper {
    display:flex;
    align-items: center;
    justify-content:center;
  }
  	.coin-container {
  		display: flex;
  		align-items: center;
    justify-content: space-around;
  		 .coin-list {
  		 	.coin-details {
  		 		display: flex;
  		 		align-items: center;
  		 		justify-content: center;
  		 		flex-direction: column;
  		 		padding: 0.6em;
  		 		cursor: pointer;
  		 		border-radius: 6px;
  		 		&:hover {
  		 			background: #CCC;
  		 		}

  		 		img {
	  		 		width: 50px;
	  		 	}
	  		 	label {
	  		 		font-size: 1em;
	  		 	}
  		 	}
  		 	
  		 }
  	}

  .b-modal{
    width: 400px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2; 
    max-height: calc(100vh - 110px);
    overflow-y: auto;
  }
  .error {
  	color: red;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
        margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    svg {
    	fill: #FFF;
    	cursor: pointer;
    }
  }
.modal-title img{
    width: 4rem;
  }

  h4 {
  	margin: 0;
  }
  
  .btn-send {
       background-image: linear-gradient(to right, #361c56, #452469);
    margin-top: 1em;
    width: 100%;
    color: #9999ff;
    padding: 10px;
    border-radius: 10px;
    
 }

.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  } 

   @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }
  </style>