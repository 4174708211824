<template>
  <div class="main-content">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
    <div class="header-wrapper">
      <a href="/">
        <img class="logo" :src="require('@/assets/logo.png')" >
      </a> 
      
    </div> 
    <div class="wrapper">
      <div class="progress-bars">
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 1 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 2 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 3 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 4 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 5 }"></div>
      </div>
      <h3 class="text-white d-flex justify-content-center"> INDIVIDUAL ACCOUNT</h3>

    </div>

   
    <div class="content-wrapper">
      <div class="modal-body">
        <div class="alert alert-danger" v-if="errors.profile.show">
          {{ errors.profile.msg }}
        </div>
        <div>
          <div v-if="this.$route.params.step == 3">
            <form v-on:submit.prevent="submitKyc" >
              <h5 class="text-white">Basic KYC</h5>
              <hr>
                <p class="text-white">{{errorMsg}}</p>
              <fieldset>
                <div class="box" >
                  <label for="formGroupExampleInput">Middle Name: <strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="profile.middleName" @input="filterInput">
                </div>
                <div class="box" >
                  <label for="formGroupExampleInput">Email Address: <strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.emailAddress"  :disabled="isEmail">
                </div>
                <div class="box mobile-container">
                  <label for="loginId">Mobile Number: <strong>*</strong></label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn btn-flag" type="button" v-on:click="toggleCountryList" :disabled="isMobile">
                        <img class="default-flag" :src="require('@/assets/signup_flags/' + country + '.png' )">
                        <span class="text-white">{{ mobileCode }}</span>
                      </button>
                    </div>
                    <input type="tel" class="form-control" v-model="init.profile.mobileNumber" @keypress="isNumber($event)">
                  </div>
                  <div class="country-list" v-if="showCountry">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)" :disabled="isMobile" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
           
                <fieldset>
                  <legend class="legend">Birthdate:<strong>*</strong></legend>
                  <div class="three-column">
                    <div class="form-row form-group">
                      <label for="day">Day:</label>
                      <select v-model="birthDate.day" @change="updateDate" class="form-select">
                        <option value="">DD</option>
                        <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                      </select>
                    </div>
                   
                    <div class="form-row form-group">
                      <label for="month">Month:<strong>*</strong></label>
                      <select v-model="birthDate.month" @change="updateDate" class="form-select">
                        <option value="">MM</option>
                        <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                      </select>
                    </div>
                    <div class="form-row form-group">
                      <label for="year">Year:<strong>*</strong></label>
                    <select v-model="birthDate.year" @change="updateDate" class="form-select">
                      <option value="">YYYY</option>
                      <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                    </select>
                    </div>
                  </div>
                </fieldset>
              
                <fieldset>
                  <legend class="legend">Complete Address:</legend>
                  <div class="box2">
                    <label for="address">Address 1:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="address.numberStreet">
                  </div>
               
                  <div class="box2">
                    <label for="address">City:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="address.cityTown">
                  </div>
                </fieldset>

                <fieldset>
                  <div class="box">
                    <label for="address">Province:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="address.provState">
                  </div>
                  <div class="box mobile-container">
                    <label for="business">Country:<strong>*</strong></label>
                    <div class="input-group mb-3 ">
                      <!-- <div class="input-group-prepend">
                        <button class="btn btn-flag" type="button" v-on:click="toggleCountryListForNationality" >
                          <img class="default-flag" :src="require('@/assets/signup_flags/' + ncountry + '.png' )">
                        </button>
                      </div> -->
                      <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="extended.nationality"  v-on:click="toggleCountryListForNationality" placeholder="select country">
                    </div>
                    <div class="country-list" v-if="showCountryForNationality">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <label for="address">Zipcode:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="address.zipcode">
                  </div>
                </fieldset>

                <fieldset>
                 
                </fieldset>

                  <br>
                  <div class="alert alert-danger" v-if="errors.profile.show">
                    {{ errors.profile.msg }}
                  </div>

                  <div class="form-group ">
                    <button type="submit" class="btn btn-main" id="loginBtn" :disabled="isSubmitting">Submit KYC</button>
                  </div>
            </form>
            <!-- <button class="btn btn-cancel" id="loginBtn" v-on:click="goToHome">Skip</button> -->
          </div>
         
       
          <div v-if="this.$route.params.step == 4">

            <form v-on:submit.prevent="submitExtended"  class="enhanced">
              <h5 class="text-white">Enhanced KYC</h5>
              <hr>

              <fieldset>
                <div class="box2">
                  <label for="marital-status">Marital Status:<strong>*</strong></label>
                  <select id="marital-status" v-model="extended.selectedMaritalStatus" class="form-control form-select">
                    <option value="" disabled>Select Status</option>
                    <option value="S">Single</option>
                    <option value="M">Married</option>
                    <option value="D">Divorced</option>
                    <option value="W">Widowed</option>
                  </select>
                  </div>

                  <div class="box2 mobile-container">
                    <label for="business">Nationality:<strong>*</strong></label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <!-- <button class="btn btn-flag" type="button" v-on:click="toggleCountryListForNationality" >
                          <img class="default-flag" :src="require('@/assets/signup_flags/' + ncountry + '.png' )">
                        </button> -->
                      </div>
                      <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="extended.nationality"  v-on:click="toggleCountryListForNationality" placeholder="select nationality">
                    </div>
                    <div class="country-list" v-if="showCountryForNationality">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </fieldset>
           

             <fieldset>
              <div class="box">
                <label for="address">Place of Birth:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="extended.placeOfBirth">
              </div>
              <div class="box">
                <label for="address">Maiden Name:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="extended.maidenName" placeholder="Mother's maiden name">
              </div>
              <div class="box">
                <label for="address">ID number:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="extended.idNumber">
              </div>
             </fieldset>
             <fieldset>
              <div class="box mobile-container">
                <label for="business">Monthly Income:<strong>*</strong></label>
              <select id="source-funds" v-model="extended.monthlyIncome" class="form-select">
                <option disabled value="">Select</option>
                <option value="50000">below 50000</option>
                <option value="100000">50,000-100,000</option>
                <option value="500000">101,000-500,000</option>
                <option value="500001">above 500,001</option>
              </select>
            </div>
              <div class="box">
                <label for="address">Occupation:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="extended.occupation">
              </div>
              <div class="box">
                <label for="address">Employer:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="extended.employer">
              </div>
              

             </fieldset>
             <fieldset>
              <div class="box2">
                <label for="address">Nature of work:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="extended.natureOfWork">
              </div>
            
              <div class="box2">
                <label for="source-funds">Source of Funds:<strong>*</strong></label>
                <select id="source-funds" v-model="extended.sourceOfFunds" class="form-control form-select">
                  <option value="" disabled>Select source of funds</option>
                  <option value="selfEmployed">self-employed</option>
                  <option value="salary">salary</option>
                  <option value="investment">investment</option>
                </select>
              </div>
             </fieldset>


              <br>
              <div class="alert alert-danger" v-if="errors.profile.show">
                {{ errors.profile.msg }}
            </div>
            <br>
              <button type="submit" class="btn btn-main" id="loginBtn" :disabled="isSubmittingExtended">Submit Extended Info</button>
            </form>
           
            <!-- <button class="btn btn-cancel" id="loginBtn" v-on:click="goToHome">Skip</button> -->

          </div>
          <div v-if="this.$route.params.step == 5">
           
              <div class="form-group" style="color: white; padding: 20px; font-size: 1rem;">
                <p>Take a photo of your identification card and supporting documents. 
                  <ol style="color: white; padding: 20px; font-size: 1rem;">
                    <li>
                       VALID ID
                    </li>
                    <li>
                      SELFIE
                   </li>
                    <li>
                       PROOF OF ADDRESS
                    </li>
                  </ol>
                  </p>
                  
                  <p> The photo should be: 
                  <ol style="color: white; padding: 20px; font-size: 1rem;">
                    <li>
                      1. bright and clear
                    </li>
                    <li>
                      2. all corners of the document should be visible.
                    </li>
                  </ol>
               
                </p>
                

              </div>
           
              <div>
                <br>
                <hr>
                <br>
               
                <div v-if="poid.length > 0">
                  <label>UPLOADED</label>
                  <button class="btn btn-display"
                    v-for="(item, index) in poid"
                    :key="index"
                    :class="getClass(item)"
                    
                  >
                    {{ item.poidDesc }} - {{item.approvalStatus}}
                  </button>
                </div>
              </div>
             
              <button class="btn btn-main"  @click="openUpload" v-if="!uploaded">Upload Documents</button>
              <button class="btn btn-cancel" id="loginBtn" v-on:click="goToHome" v-if="uploaded">Home</button>
          </div>

        </div>
      </div>
      <Upload v-if="showUpload" @close="openUpload"></Upload>
      <view-id v-if="showDetails" @close="viewId()" :poidItem="poidItem"></view-id>
      
    </div>

  </div>
</template>

<script>
  import Upload from '@/components/modals/Upload.vue'
  import ViewId from '@/components/modals/ViewId.vue'
  export default {
    name: 'IndividualComponent',
    components: {
    ViewId,
      Upload
    },
    computed: {
      show() {
        return this.$store.state.show;
      },
      init() {
        return this.$store.state.init;
      },
    },
    data: function() {
      return { 
        accountType: '',
        
        profile:{
            middleName: ''
        },
        isNumberValid: true,
        isDateValid: true,
        isEmail: false,
        isMobile: false,
        initData:"",
        birthdate:'',
        birthDate:{
              day: '',
              month: '',
              year: ''
            },
            day: '',
            month: '',
            year: '',
            days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
        
        formattedBirthdate: '',
        isUnderage: false,
       
        errorMsg:"",
        address:{
          numberStreet:"",
          cityTown:"",
          provState:"",
          zipcode:""

        },
        extended:{
          birthOfPlace: "",
          occupation:"",
          maidenName:"",
          natureOfWork:"",
          sourceOfFunds:"",
          selectedMaritalStatus:"",
          employer:"",
          idNumber:"",
          homePhone: '11111',
          monthlyIncome:'0'
        },

        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        showCountry: false,
        showCountryForNationality: false,
        selected: 'mobile',
        country: 'PH',
        ncountry:'PH',
        mobileCode: '63',
        hideOrShow: "Show",
        list: {
          mobileCodes: []
        },
        isError: false,
        captchaString: "",
        showUpload: false,
        numberValue: '',
        isSubmitting: false,
        isSubmittingExtended: false,
        uploaded: false,
        poid:[],
        poidItem:'',
        showDetails: false,
        poidData:''
        
    
       
      }
    },
    computed:{
       init() {
        	return this.$store.state.init;
    	},
     
      show() {
        return this.$store.state.show;
      },

    },
    methods: {
        getProfile() {
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                console.log(response.data)
                this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                this.profile.firstName = response.data.firstName
                this.profile.middleName = response.data.middleName
                this.profile.lastName = response.data.lastName
                this.profile.emailAddress = response.data.emailAddress
                this.profile.mobileNumber = response.data.mobilePhone
                this.birthDate.day = response.data.birthDd
                this.birthDate.month = response.data.birthMm
                this.birthDate.year = response.data.birthYear
                this.extended.idNumber = response.data.identityNumber
                this.extended.placeOfBirth = response.data.placeOfBirth
                this.extended.maidenName = response.data.mothersMaidenName
                this.extended.occupation = response.data.occupation
                this.extended.employer = response.data.employer
                this.extended.natureOfWork = response.data.natureOfWork
                this.extended.sourceOfFunds = response.data.sourceOfFunds
                this.extended.selectedMaritalStatus = response.data.maritalStatus

                if(response.data.presentAddress != null){
                    this.address.numberStreet = response.data.presentAddress.numberStreet
                    this.address.cityTown = response.data.presentAddress.cityTown
                    this.address.provState = response.data.presentAddress.provState
                    this.address.zipcode = response.data.presentAddress.postalCode
                }
                console.log(response.data)

                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
      toggleCountryListForNationality(){
        this.showCountryForNationality = !this.showCountryForNationality;
      },
      updateDate() {
      if (this.day && this.month && this.year) {
        // Do any additional validation or formatting if needed
        const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
        console.log('Selected Date:', formattedDate);
      }
    },
      selectCountryForNationality: function(c) {
				this.showCountryForNationality = false;
        this.ncountry = c.countryCode
        this.extended.nationality = c.country
			},
      getPoidType(){
        const options = {
              method: 'GET',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              url: '/cx/poids',
            }

            this.axios(options)
                .then((response) => {               
               this.poid = response.data
                  if(this.poid != 0){
                    this.uploaded = true
                  }
                }).catch((err) => {
                console.log(err);

                })

      },
      getClass(item) {
      if (item.approvalStatus === 'APPROVED') {
        if (item.poidDesc === 'validId' || item.poidDesc === 'poa' || item.poidDesc === 'selfie') {
          return 'green';
        }
      }

      return ''; // Return an empty string if no special class is required
    },
   
    viewId(details){
            this.poidItem = details
            console.log(details);
            
            this.showDetails = !this.showDetails
            
        },
  
    handleResponse(response) {
      this.poid = response.data;
    },
      selectAccount(accountType){
        this.accountType = accountType
        console.log(accountType)
        if(this.accountType == 'individual'){
          this.$router.push({
              name: 'Individual',
              params: {
                step: 3
              }
            });
        }
        if (this.accountType == 'corporate'){
          window.location.href = "/#/corporate-setup-profile";
        }
      },
      openUpload(){
                this.showUpload = !this.showUpload
      },
      goToHome:function(){
        this.$router.push({name: 'Dashboard'})
      },
    
      validateNumber() {
        const maxValue = 2008; // Maximum value you want to allow
          if (this.birthDate.year > maxValue) {
            this.birthDate.year = maxValue.toString(); // Set the value to the maximum
          }
        },
        validateNumberDay() {
        const maxValue = 31; // Maximum value you want to allow
          if (this.birthDate.day > maxValue) {
            this.birthDate.day = maxValue.toString(); // Set the value to the maximum
          }
        },
        validateNumberMonth() {
        const maxValue = 12; // Maximum value you want to allow
          if (this.birthDate.month > maxValue) {
            this.birthDate.month = maxValue.toString(); // Set the value to the maximum
          }
        },
      validateBirthdate() {
        const date = new Date(this.birthdate);
        const today = new Date();
        const age = today.getFullYear() - date.getFullYear();
        const monthDiff = today.getMonth() - date.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
          age--;
        }
        this.isUnderage = age < 18;
        if (!this.isUnderage) {
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const year = date.getFullYear();
          this.month = month < 10 ? '0' + month : month.toString();
          this.day = day < 10 ? '0' + day : day.toString();
          this.year = year.toString();
        }
       
      },

      getInitialSetup(){
				this.axios.get('/cx/initialsetupdata', {
					headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
				})
						.then((response) => { 
							this.init.profile.mobileNumber = response.data.mobilePhone;
							this.init.profile.emailAddress = response.data.emailAddress;
							if(this.init.profile.mobileNumber){
								this.isMobile = true
								this.initData = 'mobile';
							}
							if(this.init.profile.emailAddress){
								this.isEmail = true;
								this.initData = 'email';
							}
						
						}).catch((err) => {
							console.log(err)

						})
			},
      toggleCountryList: function(){
          this.showCountry = !this.showCountry;
        
        },
       
      selectCountry: function(c) {
				this.showCountry = false;
				this.country = c.countryCode;
				this.mobileCode = c.mobileCode;
			},
		
      getMobileCodes: function() {
      this.axios.get('/assets/mobilecodes.json')
        .then((response) => {
          this.list.mobileCodes = response.data;
        }).catch((err) => {
          console.log(err);

        })
    },

      filterInput(){
        this.profile.middleName = this.profile.middleName.replace(/[^A-Za-z]/g, '');
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
     
      submitKyc(){
        let hasError = false;
        this.isSubmitting = false
       
          
            if(this.address.numberStreet == "" || this.address.numberStreet == null || this.address.numberStreet == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Street is required";
            hasError = true
            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
            return false;
            }
            if(this.address.cityTown == "" || this.address.cityTown == null || this.address.cityTown == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Address is required";
            hasError = true
            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
            return false;
            } 
              if(this.address.provState == "" || this.address.provState == null || this.address.provState == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "State is required";
            hasError = true
            return false;
            }
          
            if(this.address.zipcode == "" || this.address.zipcode == null || this.address.zipcode == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Postal Code is required";
            hasError = true
            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
            return false;
            }

            if(this.init.profile.mobileNumber == "" || this.init.profile.mobileNumber == null || this.init.profile.mobileNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Mobile number is required";
            hasError = true
            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
            return false;
            }
            


            if(this.init.profile.emailAddress == "" || this.init.profile.emailAddress == null || this.init.profile.emailAddress == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Email Address is required";
            hasError = true
            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
            return false;
            } 

            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
          
            if(hasError == false){

            const params = {
                firstName: this.init.profile.firstName,
                lastName: this.init.profile.lastName,
                middleName: this.profile.middleName,
                birthYear: this.birthDate.year,
                birthMm: this.birthDate.month,
                birthDd: this.birthDate.day,
                permanentAddrSameAsPresent: true,
                nationality: this.ncountry,
                grossMonthlyIncome: this.extended.monthlyIncome,
                "presentAddress.cityTown": this.address.cityTown,
                "presentAddress.country":this.country,
                "presentAddress.numberStreet": this.address.numberStreet,
                "presentAddress.postalCode":this.address.zipcode,
                "presentAddress.provState": this.address.provState,
                  }
                  if(this.initData == "mobile"){
                  params.emailAddress = this.init.profile.emailAddress;
                  }

                  if(this.initData == "email"){
                  params.mobilePhone = this.mobileCode + this.init.profile.mobileNumber;
                  }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


                const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/cx/submitbasicinfo',
                };

                this.axios(options)
                .then((response) => {
                    console.log(response.data)
                    this.isSubmitting = true
                    this.$router.push({
                      name: 'Individual',
                      params: {
                        step: 4
                      }
                    });

                }).catch((err) => {
                console.log(err.response.data)
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.msgText
                   


                if(err.response.data.fieldErrors){
                    
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(()=>{
                          this.errors.profile.show = false;
                        this.errors.profile.msg = ""
                    }, 4000)

                }
                    
                })
            }

      },
      submitExtended(){
       
        let hasError = false;
                if(this.extended.placeOfBirth == "" || this.extended.placeOfBirth == null || this.extended.placeOfBirth == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Place of Birth is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 
                 if(this.extended.maidenName == "" || this.extended.maidenName == null || this.extended.maidenName == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Maiden name is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 

                if(this.extended.occupation == "" || this.extended.occupation == null || this.extended.occupation == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Occupation is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 
                 if(this.extended.natureOfWork == "" || this.extended.natureOfWork == null || this.extended.natureOfWork == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Nature of work is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 
                 if(this.extended.employer == "" || this.extended.employer == null || this.extended.employer == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Employer is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 
                 if(this.extended.idNumber == "" || this.extended.idNumber == null || this.extended.idNumber == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Valid ID Number is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 
                 if(this.extended.sourceOfFunds == "" || this.extended.sourceOfFunds == null || this.extended.sourceOfFunds == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Source of funds is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 
               
                 if(this.extended.selectedMaritalStatus == "" || this.extended.selectedMaritalStatus == null || this.extended.selectedMaritalStatus == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Marital Status is required";
                hasError = true
                setTimeout(()=>{
                     this.errors.profile.show = false;
                     this.errors.profile.msg = ""

                }, 2000)
                return false;
                } 


                this.errors.profile.show = false;
                this.isSubmittingExtended = false
                this.submitKyc();
                const params = {
                    firstName: this.init.profile.firstName,
                    lastName: this.init.profile.lastName,
                    middleName: this.profile.middleName,
                    birthYear: this.birthDate.year,
                    birthMm: this.birthDate.month,
                    birthDd: this.birthDate.day,
                    permanentAddrSameAsPresent: true,
                    "presentAddress.cityTown": this.address.cityTown,
                    "presentAddress.country":this.country,
                    "presentAddress.numberStreet": this.address.numberStreet,
                    "presentAddress.postalCode":this.address.zipcode,
                    "presentAddress.provState": this.address.provState,
                    nationality: this.country,
                    identityNumber:this.extended.idNumber,
                    placeOfBirth:this.extended.placeOfBirth,
                    mothersMaidenName:this.extended.maidenName,
                    occupation: this.extended.occupation,
                    maritalStatus: this.extended.selectedMaritalStatus,
                    natureOfWork: this.extended.natureOfWork,
                    employer: this.extended.employer,
                    sourceOfFunds: this.extended.sourceOfFunds,
                    homePhone: this.extended.homePhone,
                    grossMonthlyIncome: this.extended.monthlyIncome,
                    
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


                const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/cx/profile/updatebasic',
                };

                this.axios(options)
                .then((response) => {
                    console.log(response.data)
                    this.isSubmittingExtended = true
                    // window.location.href="/#/store"
                    this.$router.push({
                      name: 'Individual',
                      params: {
                        step: 5
                      }
                    });


                }).catch((err) => {
                console.log(err.response.data)     
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.msgText
                if(err.response.data.fieldErrors){
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage

                }
                    
                })
        
      },

      sessionCheck() {
        const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
          .then((response) => {
           this.init.hasSession = true;
           this.getProfile();
          }).catch((err) => {
            this.init.hasSession = false;
                // if(err.response.data.msgCode == '00001') {
                //   window.location.href = "/#/";
                // }  
              })
      },
    },
    mounted() {
    this.sessionCheck();
     this.getMobileCodes();
     this.getInitialSetup();
     this.getPoidType();
    },
    watch: {
     
    }
  }
</script>
 
<style scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    max-width: 1000px;
    min-width: 350px !important;
    margin: 10px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}


   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>