<template>
  <div  class="main-container">  
    <div class="bg"></div>
     <ViewHeader></ViewHeader>
    <div class="main-body">
    <!-- <div><button class="btn btn-secondary" @click="goToDefault"> go back</button></div> -->
      <div class="datepicker-container">
        <div class="form-group datepicker-wrapper">
          <div class="form-container">
            <label>From Date:</label>
            <input type="date" class="form-control" v-model="fromDate">
          </div>

          <div class="form-container">
             <label>To Date:</label>
            <input type="date" class="form-control" v-model="toDate">
          </div>
            <div class="form-container">
              <button class="btn btn-primary" v-on:click="getHistory(state)">Go</button>
            </div>
           
            
        </div>
      </div>



    <div class="d-flex flex-wrap justify-content-center" style="width:100%; padding-top: 50px;" >
      <button class="btn cancel" :class="{ 'btn-active': state == '00'}" style="width:33%" @click="changeState('00')">Open Orders</button>
      <button class="btn cancel" :class="{ 'btn-active': state == '11'}" style="width:33%" @click="changeState('11')">Order History</button>
      <button class="btn cancel" :class="{ 'btn-active': state == '80'}" style="width:33%" @click="changeState('80')">CANCELLED</button>
    </div>
    <div class="transaction-content"> 
          <div class="history-header"> 
          </div>
          
          <div class="history-content">
            <table>
              <tr>
                <th>ORDER TYPE</th>
                <th></th>
                
                <th>TIMESTAMP</th>
                <th>MARKET PAIR</th> 
                <th>QUANTITY</th>
                <th>PRICE VALUE</th>
                <th>TOTAL AMOUNT</th>
                <th>FEE (USDT)</th>
               
              
               
                <th v-if="state == '00'">REMAINING ORDER</th> 
                
                <th v-if="state == '00'">ACTION</th>
              </tr>
              <tr v-for="h in paginated" :key="h.hash">
                <td class="s" v-if="h.side == 'S'">{{ h.txnType }}</td>
                <td class="b" v-else>{{ h.txnType }}</td>
                <td class="s" v-if="h.side == 'S'">{{ h.side == 'S' ? 'SELL' : 'BUY' }}</td>
                <td class="b" v-else>{{ h.side == 'S' ? 'SELL' : 'BUY' }}</td>
                <td >{{ formatDate(h.txnDate) }}</td>
                <td>{{ h.market }}</td> 
                <td class="th-orange"> {{  h.side == 'S' ? h.sourceCurrency : h.targetCurrency  }} {{  h.side == 'S' ? formatAmount(h.sourceAmount) : formatAmount(h.targetAmount) }}</td>
                  <td class="th-orange">{{ formatAmount(h.xchgRate) }}</td>
                <td class="th-orange">{{ h.side == 'S' ? h.targetCurrency : h.sourceCurrency  }} {{ h.side == 'S' ? formatAmount(h.targetAmount) : formatAmount(h.sourceAmount)  }}</td>
              <td>{{ h.txnFee }}</td>
              
                
               
                <td v-if="state == '00'" class="th-orange">{{ h.side == 'B' ? h.targetCurrency : h.sourceCurrency }} {{ h.remainingOrder }}</td>
                <td v-if="state == '00'"><a v-on:click="cancelOrder(h.txnId)" href="javascript:;"> Cancel Order </a></td>
                
              </tr>
              
              <tr v-if="paginated.length == 0" >
                <td colspan="12" class="text-center"> <h3>No Data Found</h3> </td>
              </tr>
            </table>
        
          </div> 
    </div>

        <div class="justify-content-center text-center" v-if="pages > 1">
      <nav aria-label="Page navigation example" class="mt-5 mx-auto">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#" v-if="currentPage[state] > 1" @click.prevent="changePage('prev')">Previous</a></li>
          <li class="page-item" v-for="(p, index) in pages" :class="{active: currentPage[state] == index + 1}" @click.prevent="changePage(index + 1)"><a class="page-link" href="#">{{index + 1}}</a></li>
          <li class="page-item"><a class="page-link" href="#" @click.prevent="changePage('next')" v-if="currentPage[state] < pages">Next</a></li>
        </ul>
      </nav>
    </div>
    </div>

    <ConfirmCancel @close="cancelOrder" :txn-id="selectedTxnId" v-if="showConfirmCancelModal"/>
      <ConfirmLogout @close="openLogout" v-if="showLogoutModal"/>
        <Login  @close="openLogin" @openSignUp="openSignup" v-if="showLoginModal"/>
        <Signup  @close="openSignup" @openLogin="openLogin" v-if="showSignupModal"/>
      </div>

</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral'  
  import ConfirmLogout from '@/components/modals/LogoutModal.vue'
  import ConfirmCancel from '@/components/modals/ConfirmCancel.vue'
  import Login from '../components/modals/Login.vue'
  import Signup from '../components/modals/Signup.vue'
  import PageLoader from '../components/PageLoader.vue'
import ViewHeader from '../views/viewHeader.vue'



  export default {
    name: 'history',
    components: {
    ConfirmLogout,
    PageLoader,
    Login,
    Signup,
    ConfirmCancel,
    ViewHeader
},
    data: function() {
      return  {
        showLoading: true, 
        showLogoutModal: false,
        showConfirmCancelModal: false,
        showLoginModal: false,
        showSignupModal: false,
        state: '00',
        selectedTxnId: "",
        pages: '',
        currentPage: {
          '11': 1,
          '00': 1,
          '80': 1
        },
        pageSize: 15,
        fromDate: "",
        toDate: "",
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },

      chooseAsset() {
        this.selectedSubWallet = this.$t('blxDashboard.chooseAsset')
        return this.selectedSubWallet
      },

      indexStart() {
        return (this.currentPage[this.state] - 1) * this.pageSize;
      },

      indexEnd() {
        return this.indexStart + this.pageSize;
      },

      paginated() {

        if(this.init.history.length > 0) {
          return this.init.history.slice(this.indexStart, this.indexEnd);
        } else {
          return [];
        }
        
      }
    },
    methods: {
       getFunds: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/funds',
        };

        this.axios(options)
           .then((response) => { 

            this.init.balance = response.data;
            console.log(this.init.balance)

            }).catch((err) => {
              
            })
      },
        changeState(state) {
            this.state = state;

            this.getHistory(state);
        },

        formatInitDate: function(d){ 
          let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
          let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
          let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
          
          return `${ye}-${mo}-${da}`;
        },

        formatApiDate: function(d){ 
          let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
          let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
          let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
          
          return `${mo}/${da}/${ye}`;
        },


        initDate: function(){
          let date = new Date();

          this.fromDate = this.formatInitDate(new Date(date.getFullYear(), date.getMonth(), 1))
          this.toDate = this.formatInitDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

 
 
        },
        getHistory: function(status){

          
 
        const params = {
          status: status,
          fromDate:this.formatApiDate(new Date(this.fromDate)) ,
          toDate: this.formatApiDate(new Date(this.toDate))
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');



        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/trade/history',
        };

        this.init.history = [];

        this.axios(options)
          .then((response) => {
              this.init.history = response.data.rowsList
              this.getPages(response.data.rowsList.length)
          })
      },

      getPages(length) {
        let roundedLength = Math.ceil(length/15)
        this.pages = roundedLength
        console.log(roundedLength)
      },
      
      goToDefault() {
        this.$router.go(-1);
       // this.$router.push({name: 'MarketDashboard', params: {pair: 'BTCUSDT'}})
      },

    openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      openLogin(){
        this.showLoginModal = !this.showLoginModal;
        this.showSignupModal = false;
      },
      openSignup(){
        this.showSignupModal = !this.showSignupModal;
        this.showLoginModal = false;
      },

      formatDate: function(time){
        return new Date(time).toLocaleString();
      },
      formatAmount:  function (num) {

        if(isNaN(num)) {
            num = 0;
        }
        return parseFloat(num).toFixed(5)
      },

      cancelOrder: function(txnId){ 
        this.selectedTxnId = txnId;
        this.showConfirmCancelModal = !this.showConfirmCancelModal;
        
        if(!this.showConfirmCancelModal) {
          this.getHistory('00');
        }

      },

      changePage(page) {
        if(page == 'prev') {
          this.currentPage[this.state]--
        } else if(page == 'next') {
          this.currentPage[this.state]++
        } else {
          this.currentPage[this.state] = page
        }

        console.log(this.currentPage[this.state])
      },

 
      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.init.hasSession = true
              setTimeout( () => {
                this.showLoading = false;
              },1000)

            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                this.showLoading = false;
                this.openLogin();
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                  window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
            this.$bvModal.show('logout-modal'); 
            console.log('.')
      },
    },

    beforeMount(){

    },

    mounted() {
        this.checkSession();
        this.initDate();
        this.getHistory('00');
        this.getFunds();
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  .s{
    color: red !important;
  }
  .b{
    color: green !important;
  }
  .datepicker-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .datepicker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre;
    color: #FFF;
    width: 45em;
      
  }

  .datepicker-wrapper .form-container {
    display: flex;
        align-items: center;
  }
 .datepicker-wrapper label {
        margin: 0 0.5em;
      }
      .datepicker-wrapper button {
        margin-left: 1em;
      }
  #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #FFF;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #141414;
}
#loading .content {
  display: inline-block;
  text-align: center;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
}

.fa-icon { 
    color: #FFF;
    fill: #fff;
}
  .z-index-2 {
    z-index: 2;
  }

  .main-container {
    font-family: 'Medium';
    height:100%;
  }
  .main-body {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    padding:30px;
  }
  .bg {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }
  table {
    font-family: 'Medium';
    border-collapse: collapse;
    width: 100%;
    color: #333;
  }
   .hash {
    white-space: nowrap !important;
    width: 20em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  td, th {
   
    text-align: left;
    padding: 1em;
    font-size: 0.75em;
    color: #FFF !important;
  }

  td {  
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    color: white;
    border-bottom: 1px solid #FFF;
    padding: 1.5em;
  
  }

  th {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    color: #FFF !important;
  }

  th:first-child { 
    border-top-left-radius: 6px;
  }
  th:last-child { 
    border-top-right-radius: 6px;
  }

  .history-content {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius: 6px;
        overflow-x:auto;
  }
    


  .history-container {  
      padding: 1.5em;
      border-radius: 6px;
      flex-direction: column;
      position: relative;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      padding-top: 5em;
      color: #000;
      background-color: #de8413;
      background-image: linear-gradient(#de8413, #d8962c);
      width: 100%;
      margin: 0 2em;
  }
  
  .history-header {  
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;;
  }


  .option-box {

    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border: 1px solid #8c5b13;
    color: #FFF;
    border-radius: 6px; 
    min-height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3em;
    cursor: pointer;
  }
 .content-icon {
      width: 75px;
    }

  .header { 
    
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
  }
  .logout {
    color: #FFF;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1em;
    padding: 0 15em;
  }

  .btn-secondary {
      background:white !important;
      border-color:rgba(72, 57, 28, 0.978) !important;
      color:black !important;
      border-width:2px !important;
      margin-left:5px !important;
    }

   

    .cancel {
	
	  background:none;
	  border:none;
	  font-family: 'Medium';
	  width:15%;
    outline:none;
    color: #FFF !important;
  }

  .cancel:hover {
    color:#8585e5;
  }

  .cancel:focus {
    box-shadow:1px 1px #8585e5;
  }

  .select {
	  color:#8585e5;
	  background:none;
	  border-color:#8585e5;
	  border-width:1px;
	  font-family: 'Medium';
  }

  .btn-active {
    border:none;
	  border-bottom:1px #8585e5 solid;
	  font-family: 'Medium';
	  width:15%;
    color: #FFF !important;
  }

  .column {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row ;
  justify-content: space-evenly;
   border: 1px solid black;
}
.row {
    margin: 0 -5px;
    display: flex !important;
    color: white !important;
}
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 16px;
  text-align: center;
  background: transparent;
    backdrop-filter:blur(20px);
	box-shadow:0 0 30px rgba(0,0,0,0.3);
	/* border: 2px solid #8585e5; */
  display: flex;
  color: white !important;
  width: auto;
  margin: 10px;
  
}

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
   .datepicker-wrapper label{
      
      font-size: 0.8em;
    }
    .datepicker-wrapper .form-control {
      font-size: 0.8em;
    }
    .main-body {
      padding: 1em;
    }
    .content {
      padding: 0 3em;
    }

    .transaction-content {
      padding: 1em;
    }
    .column {
      flex-direction: column;
    } 

    .datepicker-wrapper {
      width: 100%;
      
        flex-direction: column;
    }

    .datepicker-container {
          flex-direction: column;
    }

    .datepicker-wrapper .form-container {
          flex-direction: column;
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }


</style>



