<template>
    <div class="main-content">
      <img :src="require('../assets/images/bg1.jpg')" class="bg" />
      <div class="header-wrapper">
        <viewHeader />
        
      </div> 
      
      <div class="content-wrapper">
        <!-- <button class="btn btn-back" @click="goBack()">back</button> -->
       
        <br>
       

       
        <div class="modal-body">
           <p>{{errorMsg}}</p>
            <div>
              <br>
              <hr>
              <br>
              <div class="three-column" >
              
                <div>
                  <button  class="btn-card"
                    v-for="(item, index) in poid"
                    :key="index"
                    :class="getClass(item)"
                    @click="handleButtonClick(item)"
                  >
                    {{ item.poidDesc }}
                  </button>
                </div>

              <ul class="btn-group-card" v-if="!corporateAccount">
                <li>
                  
                  <button class="btn-card-upload" :class="{ 'green': this.isVerified == true}"  @click="goToProfile">
                    Individual Account
                  </button>
                 
                </li>

                <br>
                <hr>
                <br>
                </ul>

                <ul class="btn-group-card" v-if="corporateAccount">
                <li >
                  
                  <button class="btn-card-upload" :class="{ 'green': this.init.profile.status == 'VERIFIED'}"  @click="goToCorporateStep1">
                    Business Information
                  </button>
                 
                </li>
                <li>
                  <button class="btn-card-upload" :class="{ 'green':  this.init.profile.status == 'VERIFIED'}" @click="goToCorporateStep2">
                    Business Operation Information
                  </button>
                </li>
                <li>
                  <button class="btn-card-upload" :class="{ 'green':  this.init.profile.status == 'VERIFIED'}" @click="goToCorporateStep3">
                    Registrant Personal Information - Authorized account holder
                  </button>
                </li>
               
                <li>
                  <button class="btn-card-upload" :class="{ 'green':  this.init.profile.status == 'VERIFIED'}" @click="goToCorporateStep4">
                    Corporate documents
                  </button>
                </li>
                <li>
                  <button class="btn-card-upload" :class="{ 'green':  this.init.profile.status == 'VERIFIED'}" @click="goToCorporateStep5">
                    Individual Associates
                  </button>
                </li>
               
              </ul>
            

            </div>


  


            </div>

          </div>
    </div>
    <Upload v-if="showUpload" @close="openUpload"></Upload>
    <LogoutModal v-if="showLogoutModal" @close="showLogoutModal" />
    <ChangePin v-if="showChangePin" @close="openChangePin"></ChangePin>

  </div>
</template>

<script>
    import viewHeader from '@/views/viewHeader.vue'
    import Upload from '@/components/modals/Upload.vue'
    import LogoutModal from '@/components/modals/LogoutModal.vue'
    import ChangePin from '@/components/modals/ChangePin.vue'

    export default {
        name: 'Verification',
        components: {
            viewHeader,
            Upload,
            LogoutModal,
            ChangePin
        },
      
         data: function() {
        return { 
          isVerified: false,
          corporateAccount: false,
          showChangePin: false,
          showLogoutModal: false,
          showUpload: false,
          poid:[],
          accountType: '',
          errorMsg:''
        
        }
        },
        computed:{
          show() {
            return this.$store.state.show;
          },
          init() {
              return this.$store.state.init;
          },
         

        },
        methods:{
          getProfile() {
              const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                  console.log(this.init.corpDetails)
               
                this.init.profile = response.data;
                if(response.data.corpDetails.corpOfficeAddress == undefined || response.data.corpDetails.corpInfo == undefined ||  response.data.corpDetails.tradingAddress == undefined){
                  this.corporateAccount = false
                }else{
                  this.corporateAccount = true
                }
                if(response.data.corpDetails.corpInfo != '' || response.data.corpDetails.corpInfo != null || response.data.corpDetails.corpInfo != undefined){
                  this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                }else{
                  this.corporateAccount = true
                }
                if(response.data.corpDetails.corpOfficeAddress != '' || response.data.corpDetails.corpOfficeAddress != null || response.data.corpDetails.corpOfficeAddress != undefined){
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                }else{
                  this.corporateAccount = true
                }
                if(response.data.corpDetails.tradingAddress != '' || response.data.corpDetails.tradingAddress != null || response.data.corpDetails.tradingAddress != undefined){
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                }else{
                  this.corporateAccount = true
                }
                // if(this.init.corpDetails != '' || this.init.corpDetails != null || this.init.corpDetails != undefined){
                //   this.corporateAccount = true
                // }
               
                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
          goToCorporateStep1(){
            if(this.init.corpDetails.corpInfo == undefined){
              this.errorMsg = 'No data found'
              setTimeout(()=>{
                this.errorMsg = ""
              },2000)
            }else 
            if(this.init.corpDetails.corpInfo != undefined){
                  this.$router.push({
                  name: 'Corporate',
                  params: {
                    step: 3
                  }
                });
            }
          },
          goToCorporateStep2(){
            console.log(this.init.corpDetails.corpOfficeAddress)
            if(this.init.corpDetails.corpOfficeAddress == undefined){
              this.errorMsg = 'No data found'
              setTimeout(()=>{
                this.errorMsg = ""
              },2000)
            }else 
            if(this.init.corpDetails.corpOfficeAddress != undefined){
              this.$router.push({
              name: 'Corporate',
              params: {
                step: 4
              }
            });
            }
            
          },
          goToCorporateStep3(){
            this.$router.push({
              name: 'Kyc',
              
            });
          },
          goToCorporateStep4(){
            this.$router.push({
              name: 'UploadDocument',
              
            });
          },
          goToCorporateStep5(){
            this.$router.push({
              name: 'BoardOfDirectors',
              
            });
          },
       
          getClass(item) {
            if (item.approvalStatus === 'APPROVED') {
              if (item.poidDesc === 'validId' || item.poidDesc === 'poa' || item.poidDesc === 'selfie') {
                return 'green';
              }
            }

            return ''; // Return an empty string if no special class is required
          },
          openChangePin(){
              this.showChangePin = !this.showChangePin
            },
          openLogout(){
            this.showLogoutModal = !this.showLogoutModal;
          },
          goBack(){
              window.location.href="/#/"
          },
          goToProfile(){
            window.location.href="/#/profile/kyc"
          },

          
          
            sessionCheck() {
              const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
                .then((response) => {
                this.init.hasSession = true;
                this.getProfile();
                }).catch((err) => {
                    this.init.hasSession = false;

                    if(err.response.data.msgCode == '00001') {
                        window.location.href = "/#/";
                    }  
                })
            },

        },
        mounted(){
            this.sessionCheck();
            
         

        }
    }
</script>
<style scoped>
  .btn-card-upload{
  background: linear-gradient(to bottom, #CFA76B 0%, #695536 100%);
  }
  .btn-card-upload:hover{
  background: linear-gradient(to bottom, #CFA76B 100%, #695536 0%);
  }
.basic{
  background-color: rgb(110, 157, 92);
  color: white;
}
.enhanced{
  background-color: rgb(74, 166, 38);
  color: white;
}
.verified{
  background-color: green;
  color: white;
}
  
    .header-wrapper{
      padding: 1em;
    }
    .content-wrapper{
      padding: 5em;
    }
  
    .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
  }
  
  .progress-bar .active {
    background-color: #695536 !important;
  }
  
  .three-column {
  display: flex;
  justify-content: space-between;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
  
}
.form-group{
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

  
  label {
    margin-right: 5px;
  }
  
  
     .btn-flag {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
     .mobile-container {
      /* position: relative; */
      .default-flag {
        width: 33px;
        margin-right: 0.25em;
      }
      
  
      .country-list {
        position: absolute;
        background: #ccc;
        color: #333;
        top: 70px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
        .row {
          margin: 0 !important;
          .col-sm-6 {
            display: flex;
            padding: 0.5em;
            align-items: center;
            cursor: pointer;
            &:hover {
              background: darken(#ccc, 10%);
            }
            .country-name {
              margin-left: 0.5em;
            }
  
            span {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  
    .flag-list-img {
      width: 33px;
    }
    .btn-back{
        float: left;
        background-color: #333;
        color: white;
    }
    .btn-save{
        float: right;
        background-color: rgb(124, 121, 121);
        color: white;
    }
    .birthdate{
        padding-top: 10px;
    }
   table{
        padding: 10px;
        border-collapse: collapse;
        width: 100% !important;
        margin: 0 10px;
        background: #5227731d !important;
        color: white;
        text-align: center !important;
        font-size: 80%;
        border-radius: 10px !important;
        padding: 0.5em 2em;
        overflow-y: scroll;
        max-height: 700px; 
        z-index: 9999 !important;
   }
   .btn-group-card{
      display: block !important;
      justify-content: center;
      align-items: center;
      width: 100%;
 }
.btn-card{
  width: 100%;
  height: 50px;
  margin: 10px;
  text-align: start;
  border-radius: 10px !important;


}

.btn-card-check{
  background: #aa6c00;
  background: linear-gradient(to top, #aa6c00 0%, #f9cb10 100%);
}
.green{
  background: green;
}
.red{
  background: rgb(96, 89, 89);
}
</style>