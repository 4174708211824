<template>

   <div class="backdrop">
     <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Change PIN</h4>

            <div>
							<v-icon name="chevron-left" color="white"  @click="closeModal()" />
						</div>


          </div>
          
        </div>
        <div style="padding: 1em">
          <div class="mx-auto">
            
          		<form @submit.prevent="changePin">
									<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
									<div class="form-container">
										<label for="amount">Current PIN:</label>
										<div class="input-group">
											<input type="password" name="amount" @keypress="isNumber($event)" maxlength="6" v-model="pin.current" class="form-control" id="current">
											<div class="input-group-append">
											  <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword('current')" style="margin-top:0px;">{{ hideOrShow.current }}</button>
											</div>
										  </div>
									</div>
									<div class="form-container mt-3">
										<label for="amount">New PIN:</label>
										<div class="input-group">
											<input type="password" name="amount" @keypress="isNumber($event)" maxlength="6" v-model="pin.new1" class="form-control" id="new1">
											<div class="input-group-append">
											  <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword('new1')" style="margin-top:0px;">{{ hideOrShow.new1 }}</button>
											</div>
										  </div>
									</div>
									<div class="form-container mt-3">
										<label for="amount">Confirm New PIN:</label>
										<div class="input-group">
											<input type="password" name="amount" @keypress="isNumber($event)" maxlength="6" v-model="pin.new2" class="form-control" id="new2">
											<div class="input-group-append">
											  <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword('new2')" style="margin-top:0px;">{{ hideOrShow.new2 }}</button>
											</div>
										  </div>
									</div>
									<div class="flex  margin-top mt-3">
										<button  class="btn btn-main" type="submit">  
											<span>Change PIN</span>	
										</button>
										 
									</div>
								</form>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'ChangPINModal',
          data(){
              return {

					      	ei: {
					      		state: 'export'
					      	},
							pin: {
								current: "",
								new1: "",
								new2: "",
							},
					      	successExport: false,
					      	showError: false,
					      	exportFile: {
					      		amount: "",
					      		secretKey: "",
					      	},
					      	importFile: {
					      		amount: "",
					      		file: null,
					      	},
					      	errors: {
					      		amount: "",
					      		secretKey: "",
					      		file: "",
					      		importSecretKey: ""
					      	},
							systemMsg: "",
							hideOrShow: {
								current: "Show",
								new1: "Show",
								new2: "Show"
							},
							showPin: {
								current: "password",
								new1: "password",
								new2: "password"
							}
					      
              }
          },
          methods:{
            closeModal() {
                this.$emit("close");
            },

	  toggleShowPassword: function(toShow){

        if(toShow == "current" && this.hideOrShow.current == "Show") {
          document.getElementById('current').type = 'text';
          this.hideOrShow.current = "Hide"
		} else if(toShow == "current" && this.hideOrShow.current == "Hide") {
          document.getElementById('current').type = 'password';
          this.hideOrShow.current = "Show"
		}
		
		else if(toShow == "new1" && this.hideOrShow.new1 == "Show") {
          document.getElementById('new1').type = 'text';
          this.hideOrShow.new1 = "Hide"
        } else if(toShow == "new1" && this.hideOrShow.new1 == "Hide") {
          document.getElementById('new1').type = 'password';
          this.hideOrShow.new1 = "Show"
        } 
		
		else if(toShow == "new2" && this.hideOrShow.new2 == "Show") {
          document.getElementById('new2').type = 'text';
          this.hideOrShow.new2 = "Hide"
        } else if(toShow == "new2" && this.hideOrShow.new2 == "Hide") {
          document.getElementById('new2').type = 'password';
          this.hideOrShow.new2 = "Show"
        }
      },

   		close: function(){
   			this.$bvModal.hide('change-pin-modal'); 
			this.pin.current = ""
			this.pin.new1 = ""
			this.pin.new2 = ""
			this.systemMsg = ""
			this.showError = false
			this.hideOrShow.current = "Show"
			this.hideOrShow.new1 = "Show"
			this.hideOrShow.new2 = "Show"
			this.toggleShowPassword();
   		},
   		
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000');
      },

      generateDigit: function (length) {
        var text = "";
        var possible = "0123456789";

        for (var i = 0; i < length; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
      },

		changePin() {
			if(this.pin.current.length == 6 && this.pin.new1.length == 6 && this.pin.new2.length == 6) {
            const params = {
                currentPin: this.pin.current,
				newPin1: this.pin.new1,
				newPin2: this.pin.new2
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                data,
                url: '/cx/changepin',
                };
                this.axios(options)
                    .then((response) => {
						this.showError = true
                        this.systemMsg = "Succesfully changed PIN."
						this.pin.current = ""
						this.pin.new1 = ""
						this.pin.new2 = ""
            setTimeout(()=>{
              window.location.reload();
            }, 7000)
                    })
                    .catch((error) => {
                        // Error code goes here
						this.showError = true
                        this.systemMsg = error.response.data.msgText
						if(this.systemMsg == "New PINs do not match") {
							this.pin.new1 = ""
							this.pin.new2 = ""
						} else if (this.systemMsg == "Invalid current PIN") {
							this.pin.current = ""
						}
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
			} else if(this.pin.current.length != 6) {
				this.pin.current = ""
				this.showError = true
				this.systemMsg = "PIN must be 6 digits"
				setTimeout(()=>{
					this.clearError()
				},10000);
			} else if(this.pin.new1.length != 6) {
				this.pin.new1 = ""
				this.showError = true
				this.systemMsg = "PIN must be 6 digits"
				setTimeout(()=>{
					this.clearError()
				},10000);
			} else if(this.pin.new2.length != 6) {
				this.pin.new2 = ""
				this.showError = true
				this.systemMsg = "PIN must be 6 digits"
				setTimeout(()=>{
					this.clearError()
				},10000);
			}
        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

        
	  isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
 
      }
    }

  </script>


  <style scoped lang="scss">

  	


  .b-modal{
    width: 400px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 50px;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
        margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    
  }
  label{
    color: black;
  }

.modal-title  svg {
    	fill: #FFF;
    	cursor: pointer;
    }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }
   @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: 175px auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }
  </style>