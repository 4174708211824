<template>
<div class="main">
  <viewHeader></viewHeader>
  <div id="main-body" >
        <div class="row">

        
          
       </div>
       <div class="btn-group mobile" >
        <button  @click="selectMarket('BTCPHP')" class="btn btn-main "  disabled>PHP</button>
        <button  @click="selectMarket('BTCUSDT')" class="btn btn-main "  >USDT</button></div>
       
           <div id="main-topbar" class="d-flex flex-wrap">
                <div class="topbar-div my-auto mr-5 ">
                  <b-form-select v-model="marketPair" class="mb-3 pair-select web-view btn btn-main" >
                    <b-form-select-option v-for="pair in pairs" :key="pair.id" :value="pair" :class="{ 'selected-option': pair === marketPair }">{{pair.label}}</b-form-select-option> 
                  </b-form-select>
                    <h6>{{ init.currency.toCurrency }} / {{ init.currency.fromCurrency }} Price</h6>
                </div>
               
                <div class="topbar-div my-auto mr-5">
                    <h4>Price</h4>
                    <h6> {{ parseFloat(receivedData.b).toFixed(5) }} {{ init.currency.fromCurrency }} </h6>
                </div>
                <div class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h Change</h6>
                    <h6 class="purple-text"> {{ marketDetails.percent_change_24 != undefined ? marketDetails.percent_change_24 : '0.00' }} %</h6>
                </div>
                <div class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h High</h6>
                    <h6>{{ marketDetails.high != undefined ? marketDetails.high : '0.00' }}</h6>
                </div>
                <div  class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h Low</h6>
                    <h6>{{ marketDetails.low != undefined ? marketDetails.low : '0.00' }}</h6>
                </div>
                <div  class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h Volume({{ init.currency.toCurrency }})</h6>
                    <h6>{{ marketDetails.volume != undefined ? marketDetails.volume : '0.00'}}</h6>
                </div>
               
            </div>
          
    
  
  <div  class="d-flex main-container">

        <div id="main-left">
          <div class="web-view">
                <div class="left-bars" >
                  <div class="btn-group-vertical" v-for="pair in pairs" :key="pair.id" :value="pair" >
                    <button class="btn btn-main php-btn" @click="selectMarketPair(pair.value)" v-if="pair.label.split('/')[1] == init.currency.fromCurrency">
                      {{pair.label}}
                  </button>
                  
                  </div>
                 
                </div>
           </div>

            <div >
                <div id="left-bars">
                    <div id="left-bar-1">
                      <label>Complete Buy Orders</label>
                        <div class="d-flex justify-content-between">
                          
                          <table>
                            <thead>
                              <tr>
                                <th>Price {{ init.currency.fromCurrency }}</th>
                                <th>Amount {{ init.currency.toCurrency }}</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody> 
                              <tr v-for="data in completedBuyOrders">
                                <td>{{ parseFloat(data.xchgRate).toFixed(2) }}</td>
                                <td>{{ parseFloat(data.targetAmount).toFixed(5) }}</td>
                                <td>{{ parseFloat(data.sourceAmount).toFixed(2) }}</td>
                              </tr>

                              <tr v-if="completedBuyOrders.length == 0">
                                <td colspan="3">No Data found</td>
                              </tr>
                            </tbody>
                          </table>
                         
                        </div>
                    </div>
                    <div id="left-bar-1" class="mt-3">
                      <label>Complete Sell Orders</label>
                        <div class="d-flex justify-content-between">
                           
                            <table>
                            <thead>
                              <tr>
                                <th>Price {{ init.currency.fromCurrency }}</th>
                                <th>Amount {{ init.currency.toCurrency }}</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody> 
                              <tr v-for="data in completedSellOrders">
                                <td>{{ parseFloat(data.xchgRate).toFixed(2) }}</td>
                                <td>{{ parseFloat(data.sourceAmount).toFixed(5) }}</td>
                                <td>{{ parseFloat(data.targetAmount).toFixed(2) }}</td>
                              </tr>

                              <tr v-if="completedSellOrders.length == 0">
                                <td colspan="3">No Data found</td>
                              </tr>
                            </tbody>
                          </table>


                        </div>
                    </div>
                </div>
              </div>
                
            </div>

            <div id="main-center" >
                  <div id="main-center-top">

                 <!--   <div  id="chartContainer" v-if="$route.params.pair == 'BLXUSDT'" style="height: 450px; width: 100%; "></div>

 -->
                  <!--   <div > -->
                      <div id="tradingview"  ></div>  
                 <!--    </div> -->



                  </div>
                  <div id="main-bottom">
                    <div class="btn-group buy-or-sell"  >
                      <button type="button" class="btn" :class="{active: buyOrSell == 'buy'}" @click="toggleBuyOrSell('buy')">BUY</button>
                      <button type="button" class="btn" :class="{active: buyOrSell == 'sell'}" @click="toggleBuyOrSell('sell')">SELL</button> 
                    </div>
                    <div class="d-flex flex-wrap">
                      <b-dropdown id="dropdown-1" :text="buyOrSellState" class="m-md-2" variant="none" toggle-class="dropdownBtn" style="background:none;color:white;border-radius:5px;">
                        <b-dropdown-item @click="switchState('Limit')">Limit</b-dropdown-item>
                        <b-dropdown-item @click="switchState('Market')">Market</b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <div class="danger alert-danger text-center" v-if="errors.buyOrSell != ''">
                        {{ errors.buyOrSell }}
                    </div>

                    <div v-if="buyOrSell == 'buy' && buyOrSellState == 'Limit'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text"  @keypress="isAmount($event);" @keydown="isUpdatingPrice=true" class="form-control mr-2" v-model="buyValues.orderPrice" @change="calculateBuyValue('price')" placeholder="Order Price"   style="margin-right:2em;background:none;border:none;color:white;" >
                        <h6 class="field-value my-auto"> Price {{ init.currency.fromCurrency }}</h6>
                      </div>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="buyValues.quantity" @change="calculateBuyValue('quantity')" placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;" >
                        <h6 class="field-value my-auto"> Qty in {{ init.currency.toCurrency }}</h6>
                      </div>
                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee (USDT): {{ fee }}</label>
                        <label v-if="this.init.balance.length > 0">Balance: {{ getBalance(init.currency.fromCurrency) }} {{ init.currency.fromCurrency }} </label>
                      </div>
                      <b-form-input type="range" v-model="buyValues.orderValue" @change="calculateBuyValue('value')" :max="parseFloat(getBalance(init.currency.fromCurrency)).toFixed(6)" step="0.00000001" style="width:100%;"  ></b-form-input>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="buyValues.orderValue"  @change="calculateBuyValue('value')" placeholder="Order Value" style="margin-right:2em;background:none;border:none;color:white;"  >
                        <h6 class="field-value my-auto"> Qty in {{ init.currency.fromCurrency }}</h6>
                      </div>
                      <button class="btn btn-buy" :disabled="!init.hasSession" @click="onBuy">BUY {{ init.currency.toCurrency }} </button>
                    </div>
                    
                    <div v-if="buyOrSell == 'sell' && buyOrSellState == 'Limit'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" @keydown="isUpdatingPrice=true" class="form-control mr-2" v-model="sellValues.orderPrice" @change="calculateSellValue('price')" placeholder="Order Price" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">Price {{ init.currency.fromCurrency }}</h6>
                      </div>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="sellValues.quantity"  @change="calculateSellValue('quantity')" placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto"> Qty in {{ init.currency.toCurrency }}</h6>
                      </div>
                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee (USDT): {{ fee }}</label>
                       <label v-if="this.init.balance.length > 0"> Balance: {{ getBalance(init.currency.toCurrency) }} {{init.currency.toCurrency}} </label>
                      </div>
                      <b-form-input type="range" v-model="sellValues.quantity" @change="calculateSellValue('quantity')" :max="parseFloat(getBalance(init.currency.toCurrency)).toFixed(5)" step="0.00000001"  style="width:100%;"></b-form-input>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="sellValues.orderValue" @change="calculateSellValue('value')" placeholder="Order Value" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto" @click="onSell">Qty in {{ init.currency.fromCurrency }}</h6>
                      </div>
                      <button class="btn btn-buy" :disabled="!init.hasSession"  @click="onSell">SELL {{ init.currency.toCurrency }}  </button>
                    </div>


                    <div v-if="buyOrSell == 'buy' && buyOrSellState == 'Market'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="buyValues.orderPrice" style="margin-right:2em;background:none;border:none;color:white;" disabled>
                        <h6 class="field-value my-auto">Market Price</h6>
                      </div>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="buyValues.orderValue"  placeholder="Price value" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">Qty in {{ init.currency.fromCurrency }}</h6>
                      </div>

                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee (USDT): {{ fee }}</label>
                        <label v-if="this.init.balance.length > 0">Balance: {{ getBalance(init.currency.fromCurrency) }} {{ init.currency.fromCurrency }} </label>
                      </div>
                      <b-form-input type="range" v-model="buyValues.orderValue" @change="calculateBuyValue('value')" :max="parseFloat(getBalance(init.currency.fromCurrency)).toFixed(6)" step="0.00000001" style="width:100%;"  ></b-form-input>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="buyValues.quantity" @change="calculateBuyValue('quantity')" placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;" >
                        <h6 class="field-value my-auto">Qty in {{ init.currency.toCurrency }}</h6>
                      </div>
                     
                      <button class="btn btn-buy" :disabled="!init.hasSession"  @click="onBuy">BUY {{ init.currency.toCurrency }}</button>
                    </div>

                    <div v-if="buyOrSell == 'sell' && buyOrSellState == 'Market'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="buyValues.orderPrice" style="margin-right:2em;background:none;border:none;color:white;" disabled>
                        <h6 class="field-value my-auto">Market Price</h6>
                      </div>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input @keypress="isAmount($event)" type="text" class="form-control mr-2" v-model="sellValues.quantity"  placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">Qty in {{ init.currency.toCurrency }}</h6>
                      </div>
                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee: {{ fee }}</label>
                        <label v-if="this.init.balance.length > 0">Balance: {{ getBalance(init.currency.toCurrency) }} {{ init.currency.toCurrency }} </label>
                      </div>
                      <b-form-input type="range" v-model="sellValues.quantity" @change="calculateSellValue('quantity')" :max="parseFloat(getBalance(init.currency.toCurrency)).toFixed(5)" step="0.00000001"  style="width:100%;"></b-form-input>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" @keypress="isAmount($event)" class="form-control mr-2" v-model="sellValues.orderValue" @change="calculateSellValue('value')" placeholder="Order Value" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto" @click="onSell">Qty in {{ init.currency.fromCurrency }}</h6>
                      </div>
                     
                      <button class="btn btn-buy" :disabled="!init.hasSession"  @click="onSell">SELL {{ init.currency.toCurrency }}</button>
                    </div>
                  </div>
                </div>
       
        <div id="main-right">
          <div class="text-center" style="font-weight:bold;">BUY</div>
          <div id="main-buy" class="d-flex justify-content-between">
           

             <table class="main-right">
                  <thead>
                    <tr>
                       <th class="left-col">Price ({{ init.currency.fromCurrency }})  </th>
                      <th class="right-col">Quantity ({{ init.currency.toCurrency }})</th> 
                    </tr>
                  </thead>

                  <tbody> 
                    <tr v-for="data in pendingBuyOrders">
                      <td class="left-col">{{ parseFloat(data.xchgRate).toFixed(5) }}</td>
                      <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(5) }}</td> 
                    </tr>

                    <tr v-if="pendingBuyOrders.length == 0">
                      <td colspan="3">No Data found</td>
                    </tr>
                  </tbody>
                </table>
          </div>
          <div class="market-price d-flex justify-content-between flex-wrap">
            <h6 class="my-auto"> Price: {{ parseFloat(receivedData.b).toFixed(5) }} {{ init.currency.fromCurrency }} / {{ init.currency.toCurrency }}</h6>
           
          </div>
          <div class="text-center" style="font-weight:bold;">SELL</div>
          <div id="main-sell" class="d-flex justify-content-between">
            <!-- <div id="sell-price-column" class="text-left">
              <h6 class="sell-price-header">Price</h6>
              <div v-for="h in init.history" :key="h.hash">
                <h6 class="buy-price-text" v-if="h.side == 'S'">{{ h.xchgRate.toFixed(5) }}</h6>
              </div>
            </div>
            <div id="sell-quantity-column">
              <h6 class="sell-quantity-header">Quantity</h6>
              <div v-for="h in init.history" :key="h.hash">
                <h6 class="buy-price-text" v-if="h.side == 'S'">{{ init.currency.fromCurrency }} {{ h.sourceAmount }}</h6>
              </div>
            </div> -->

            <table v-if="!init.hasSession">
                  <thead>
                    <tr>
                      <th class="left-col">Price ({{ init.currency.fromCurrency }})  </th>
                      <th class="right-col">Quantity ({{ init.currency.toCurrency }})</th> 
                    </tr>
                  </thead>

                  <tbody> 
                    <tr v-for="data in pendingSellOrders">
                      <td class="left-col">{{ parseFloat(data.xchgRate).toFixed(5) }}</td>
                      <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(5) }}</td> 
                    </tr>

                    <tr v-if="pendingSellOrders.length == 0">
                      <td colspan="3">No Data found</td>
                    </tr>
                  </tbody>
                </table>


          </div>
          <div :disabled="isDisabled" style="cursor:pointer;color:white;font-weight:bold; float: right; padding: 10px;" class="routelink" @click="goToBuySell">MORE ORDERS</div>
        </div>
    </div>
    <div> 
      <OpenOrders />
    </div>
    
</div>

     

          <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
          <DepositModal @close="openDeposit" v-if="showDepositModal"/>
           <Withdraw @close="openWithdraw" v-if="showWithdrawModal"/>
           <Profile @close="openProfile" v-if="showProfileModal" :status="verificationStatus"/>
          <ChangePin @close="changePin" v-if="showChangePinModal"/>
          <Login  @close="openLogin" @openSignUp="openSignup" v-if="showLoginModal"/>
          <Signup  @close="openSignup" @openLogin="openLogin" v-if="showSignupModal"/>
        
        </div>
</template>
<script>

import numeral from 'numeral'
import LogoutModal from '../components/modals/LogoutModal.vue'
import DepositModal from '../components/modals/Deposit.vue'
import Withdraw from '../components/modals/Withdraw'
import Login from '../components/modals/Login.vue'
import Signup from '../components/modals/Signup.vue'
import ChangePin from '../components/modals/ChangePin.vue'
import Profile from '../components/modals/Profile.vue'
import CanvasJSChart from '../CanvasJSVueComponent.vue';
import Pusher from 'pusher-js';
import viewHeader from '../views/viewHeader.vue';
import OpenOrders from '../components/OpenOrders.vue'
// import Dashboard from '../components/Dashboard.vue'
import io from 'socket.io-client';
import VueNativeSock from 'vue-native-websocket';



export default {
    name: 'MarketDashboard',
    components: {
        LogoutModal,
        DepositModal,
        CanvasJSChart,
        Login,
        Signup,
        ChangePin,
        Withdraw,
        Profile,
        viewHeader,
        OpenOrders,
        // Dashboard
        VueNativeSock,
    },
    data(){
        return{
          defaultPair: "ETHUSDT",
          tickers: [],
          stockChart: null,
          marketPrice: "",
          marketDetails:{},
          chartResponse: [],
          isUpdatingPrice: false,
          isDisabled: false,
          balance: {

          },
          tradingViewLnk: "",
          showChart: false,
          pendingBuyOrders: [],
          completedBuyOrders: [],
          pendingSellOrders: [],
          completedSellOrders: [],
            showLoading: true,
            showLogoutModal: false,
            showDepositModal: false,
            showWithdrawModal: false,
            showLoginModal: false,
            showSignupModal: false,
            showChangePinModal: false,
            showProfileModal: false,
            currency:'',
            buyOrSell: 'buy',
            buyValues: {
              xchgRate: '',
              orderPrice: '',
              quantity: '',
              slider: '0',
              orderValue: '0',
            },
            sellValues: {
              xchgRate: '',
              orderPrice: '',
              quantity: '',
              slider: '0',
              orderValue: '0',
            },
           
            errors: {
              buyOrSell: "",
              buyQuantity: "",
              sellQuantity: ""
            },
            pairs: [],
            tickers:[],
            marketPair: {}
            ,
            buyOrSellState: 'Limit',
            verificationStatus: 'NONE',
            fee: '',
            selectedMarket: 'BTCUSDT',
            socket: null,
            receivedData: [],
        }
    },
     computed: {
        init() {
            return this.$store.state.init;
        },
       
      },
      beforeDestroy() {
        if (this.socket) {
          this.socket.close();
        }
      },
   
    methods:{ 
      goToBuySell(){
        if(this.init.hasSession == false){
         this.isDisabled = true
        }
        if(this.init.hasSession == true){
          window.location.href='/#/buy-sell/' + this.defaultPair
        }
       
      },
      connectWebSocket() {
        console.log((this.init.currency.toCurrency.toLowerCase() + this.init.currency.fromCurrency.toLowerCase()).toLowerCase());
        // const symbol = (this.init.currency.toCurrency.toLowerCase() + this.init.currency.fromCurrency.toLowerCase()).toLowerCase();
       const symbol = this.defaultPair.toLowerCase()


       if(symbol == 'blxusdt') {
        this.getRate();
        return;
       }
 

      const endpoint = `wss://stream.binance.com:9443/ws/${symbol}@bookTicker`;



      this.socket = new WebSocket(endpoint);

      this.socket.onopen = () => {
        console.log('WebSocket connection opened');
      };

      this.socket.onmessage = (event) => {
        
        setTimeout(() => {
          const data = JSON.parse(event.data);
          // console.log('Received data:' , data);
          this.receivedData = data;
          this.marketPrice = data.b
 


          if(!this.isUpdatingPrice) {
            this.buyValues.orderPrice  = data.b
            this.sellValues.orderPrice = data.b
          }

          
          // Process the received data here
        }, 3000)

        
      };

      this.socket.onclose = () => {
        console.log('WebSocket connection closed');
        // Handle any necessary logic when the connection is closed
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        // Handle any WebSocket errors here
      };
    },
      
  
      
      isAmount: function(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;

      if ((charCode > 31 && charCode != 46  && (charCode < 48 || charCode > 57)) ) {
      evt.preventDefault();
      } else {
      return true;
      }
    },
      onChange(event){
            const selectedPair = event.target.value;
            this.init.currency.fromCurrency = selectedPair
            this.selectMarketPair(pair);
      },
      selectMarketPair(pair){
        console.log("pair: " + pair)

        this.$router.push({name: 'MarketDashboard', params: {pair: pair}})
          window.location.reload();
   
    
      },
      selectMarket(selected){
        this.selectedMarket = selected
        console.log(selected)

         localStorage.setItem("selectedPair", selected)


        this.$router.push({name: 'MarketDashboard', params: {pair: selected}})
          window.location.reload();
      
    
      },
      getCompletedHistory: function(){
        const params = {
          status: '00',
          fromDate: '12/01/2022',
          toDate: '12/31/2022'
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/trade/history',
        };

        this.axios(options)
          .then((response) => {
              this.init.history = response.data.rowsList
          }).catch((err) => {
            console.log(err)
          })
      },

      switchState(state) {
        this.buyOrSellState = state
        this.fee = ''
      },

      formatAmount: function(num){
        return numeral(num).format('0.00000');
      },
      selectMarketPair(pair){

        console.log("pair: " + pair)

        localStorage.setItem("pair", pair);
        this.$router.push({name: 'MarketDashboard', params: {pair: pair}})
        window.location.reload();
        if(this.$router.params == 'BTCPHP'){
          this.isPHP = true
        }
        if(this.$router.params == 'BTCUSDT'){
          this.isUSDT = true
        }
      },
      


      getOrderBook: function(){

 

        if(this.defaultPair != 'BLXUSDT') {
 
 
          const options = {
            method: 'GET', 
            url: '/pub/order/book?market='+ this.defaultPair +'&limit=15'
          };

          this.axios(options)
            .then((response) => {
              console.log(response)

              this.pendingBuyOrders = [];
              this.pendingSellOrders = [];

              //<td class="left-col">{{ parseFloat(data.xchgRate).toFixed(5) }}</td>
               //         <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(5) }}</td> 
              //buy
              response.data.bids.forEach((el, i) => {
                let buyObject = {};
                buyObject.xchgRate = el[0];
                buyObject.remainingOrder = el[1];

                this.pendingBuyOrders.push(buyObject);
              });

              response.data.asks.forEach((el, i) => {
                let sellObject = {};
                sellObject.xchgRate = el[0];
                sellObject.remainingOrder = el[1];

                this.pendingSellOrders.push(sellObject);
              })


              
              })
        }
        

      

        
      },

      getPairsDetails: function(){

        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/details?market=' + this.marketPair.value,
        };

        this.axios(options)
          .then((response) => {
              this.marketDetails = response.data;
            
            })
      },

      getPairs: function(){

        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/pairs',
        };

        this.axios(options)
          .then((response) => {
              
              //console.log(this.marketPair)
              this.pairs = response.data
              
              response.data.forEach((value) => {

                if(value.value == this.defaultPair) {
                  this.marketPair = value
                  this.init.currency.fromCurrency = value.label.substring(value.label.indexOf('/') + 1)
                  this.init.currency.toCurrency = value.label.substring(0, value.label.indexOf('/'))
                  
             

                  this.getPairsDetails()
                }
                

              })
              this.getRate(); 
              
            })
      },

      getFee(amount) {
        let code = ''
        if(this.buyOrSellState == 'Limit') {
          code = 'TRADE_LIMIT'
        } else {
          code = 'TRADE_MARKET'
        }

        let currency = '';

        if(this.buyOrSell == 'sell') {
          currency = this.init.currency.toCurrency;
        } 

        if(this.buyOrSell == 'buy') {
          currency = this.init.currency.fromCurrency;
        }

        let params = {
          feeCode: code,
          amount: amount,
          currency: currency 
        }
        
        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
    
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/calctxnfee',
        };

        this.axios(options)
          .then((response) => { 
            console.log(response)
            this.fee = response.data.txnFee
          })
      },

      getRate() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/price?market=' + this.defaultPair + 
               '&sourceCurrency=' + this.init.currency.toCurrency +
               '&targetCurrency=' + this.init.currency.fromCurrency,
        };

        this.axios(options)
          .then((response) => { 

            console.log("####### " + this.defaultPair);

            if(this.defaultPair == 'BLXUSDT') {
              this.receivedData.b = response.data.xchgRate;

              console.log(this.receivedData.b)
            }
            this.buyValues.orderPrice = response.data.xchgRate;
            this.sellValues.orderPrice = response.data.xchgRate;
            this.marketPrice = response.data.xchgRate;
            
          }).catch((err) => {
            
          })
      },


      calculateBuyValue(inputted) {
        console.log(inputted)
        if(inputted == 'price') {
          if(this.buyValues.quantity == '' && this.buyValues.orderValue != '') {
            this.buyValues.quantity = this.buyValues.orderValue / this.buyValues.orderPrice
           
          } else if(this.buyValues.orderValue == '' && this.buyValues.quantity != '') {
            this.buyValues.orderValue = this.buyValues.quantity * this.buyValues.orderPrice
          } else {
            this.buyValues.orderValue = this.buyValues.quantity * this.buyValues.orderPrice
            this.buyValues.quantity = this.buyValues.orderValue / this.buyValues.orderPrice
          }
        } else if(inputted == 'value') {
          if(this.buyValues.orderPrice != '') {
            this.buyValues.quantity = this.buyValues.orderValue / this.buyValues.orderPrice
            
          } 
        } else if(inputted == 'quantity') {
          if(this.buyValues.orderPrice != '') {
            this.buyValues.orderValue = this.buyValues.quantity * this.buyValues.orderPrice
            
          }
        }

        this.buyValues.quantity = parseFloat(this.buyValues.quantity).toFixed(5);
        this.buyValues.orderValue = parseFloat(this.buyValues.orderValue).toFixed(5);
        this.getFee(this.buyValues.orderValue)
      },

      calculateSellValue(inputted) {
        //this.getFee(this.sellValues.quantity)
        // if(inputted == 'price') {
        //   if(this.sellValues.quantity == '' && this.sellValues.orderValue != '') {
        //     this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
        //     console.log(this.sellValues.quantity)
        //   } else if(this.sellValues.orderValue == '' && this.sellValues.quantity != '') {
        //     this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
        //   } else {
        //     this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
        //     this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
        //   }
        // } else if(inputted == 'value') {
        //   if(this.sellValues.orderPrice != '') {
        //     this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
        //     console.log(this.sellValues.orderValue / this.sellValues.orderPrice)
        //   } 
        // } else if(inputted == 'quantity') {
        //   if(this.sellValues.orderPrice != '') {
        //     this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
        //     console.log(this.sellValues.orderValue)
        //   }
        // }

        if(inputted == 'price') {
          if(this.sellValues.quantity == '' && this.sellValues.orderValue != '') {
            this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
           
          } else if(this.sellValues.orderValue == '' && this.buyValues.quantity != '') {
            this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
          } else {
            this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
            this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
          }
        } else if(inputted == 'value') {
          if(this.sellValues.orderPrice != '') {
            this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
            
          } 
          
        } else if(inputted == 'quantity') {
          if(this.sellValues.orderPrice != '') {
            this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
            
          }
        }

        this.sellValues.quantity = parseFloat(this.sellValues.quantity).toFixed(5);
        this.sellValues.orderValue = parseFloat(this.sellValues.orderValue).toFixed(5);

        this.getFee(this.sellValues.quantity)
      },

      onBuy() {

        console.log("BuYyyy")
        let hasError = false;

        if(this.buyOrSellState == 'Limit') {

          if(this.buyValues.quantity == '' || this.buyValues.quantity == undefined || this.buyValues.quantity == null) {
            this.errors.buyQuantity = 'Quantity is required.';
            hasError = true;
          }
        }

        if(this.buyOrSellState == 'Market') {

          if(this.buyValues.orderValue == '' || this.buyValues.orderValue == undefined || this.buyValues.orderValue == null) {
            this.errors.buyQuantity = 'Value is required.';
            hasError = true;
          }
        }

        console.log(this.buyValues.quantity)
        console.log(this.buyValues.orderPrice)
        console.log(this.buyValues.orderValue)

        let url = "/cx/v2/txn/trade/buy/init";

        if(this.defaultPair == 'BLXUSDT') {
          url = "/cx/txn/trade/buy/init";
        }

        if(!hasError) {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: url,
          };
          this.axios(options)
            .then((response) => {
              let txnControlId = response.data.txnControlId
              let params = {};

              if(this.buyOrSellState == 'Limit') {
                params = {
                  market: this.defaultPair,
                  xchgRate: parseFloat(this.buyValues.orderPrice).toFixed(5),
                  sourceAmount: parseFloat(this.buyValues.orderValue).toFixed(5),
                  sourceCurrency: this.init.currency.fromCurrency,
                  targetCurrency: this.init.currency.toCurrency,
                  txnControlId: txnControlId,
                }
              } else if(this.buyOrSellState == 'Market') {
                 params = {
                  market: this.defaultPair, 
                  sourceAmount: parseFloat(this.buyValues.orderValue).toFixed(5),
                  sourceCurrency: this.init.currency.fromCurrency,
                  targetCurrency: this.init.currency.toCurrency,
                  txnControlId: txnControlId,
                }
              }
 
 

              let submitUrl = "/cx/v2/txn/trade/buy/submit";

              if(this.defaultPair == 'BLXUSDT') {
                submitUrl = "/cx/txn/trade/buy/submit";
              }

              const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
          
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                data,
                url: submitUrl,
              };

              this.axios(options)
                .then((response) => { 

                   
                   let confirmUrl = "/cx/v2/txn/trade/buy/confirm";

                  if(this.defaultPair == 'BLXUSDT') {
                    confirmUrl = "/cx/txn/trade/buy/confirm";
                  }

                  const params = {
                    txnControlId: txnControlId,
                  }

                  const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
              
                  const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    data,
                    url: confirmUrl,
                  };

                  this.axios(options)
                    .then(() => { 
                      this.getFunds();
                      this.errors.buyOrSell = "Order Submitted!"
                      this.buyValues.quantity = ""
                      this.buyValues.orderValue = 0;

                      this.getBuyOrders('00');
                      this.getSellOrders('00');
                      this.getBuyOrders('11');
                      this.getSellOrders('11');
                      //this.getBuyOrders('00');
                      this.getHistory();
                      this.getOrderBook();
                      
                      setTimeout(() => {
                        this.errors.buyOrSell = ''
                      }, 5000)
                    }).catch((err) => {
                      
                    })
                }).catch((err) => {
                  
                  this.errors.buyOrSell = err.response.data.msgText
                  if(err.response.data.fieldErrors) {
                    this.errors.buyOrSell = err.response.data.fieldErrors[0].defaultMessage
                  }

                  setTimeout(() => {
                    this.errors.buyOrSell = ''
                  }, 5000)
                })
            })
            .catch((error) => {
              console.log(error)
            });
          }
      },

      onSell() {
        
        let hasError = false;
        if(this.sellValues.quantity == '' || this.sellValues.quantity == undefined || this.sellValues.quantity == null) {
          this.errors.sellQuantity = 'Quantity is required.';
          hasError = true;
        }

        let url = "/cx/v2/txn/trade/sell/init";

        if(this.defaultPair == 'BLXUSDT') {
          url = "/cx/txn/trade/sell/init";
        }


 
        if(!hasError) {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: url,
          };
          this.axios(options)
            .then((response) => {
              let txnControlId = response.data.txnControlId
              // console.log('.')
              // const params = {
              //   market: this.$route.params.pair,
              //   xchgRate: parseFloat(this.sellValues.orderPrice).toFixed(5),
              //   sourceAmount: parseFloat(this.sellValues.quantity).toFixed(5),
              //   sourceCurrency: this.init.currency.toCurrency,
              //   targetCurrency: this.init.currency.fromCurrency,
              //   txnControlId: txnControlId,
              // }

              let params = {};

              if(this.buyOrSellState == 'Limit') {
                params = {
                  market: this.defaultPair,
                  xchgRate: parseFloat(this.sellValues.orderPrice).toFixed(5),
                  sourceAmount: parseFloat(this.sellValues.quantity).toFixed(5),
                  sourceCurrency: this.init.currency.toCurrency,
                  targetCurrency: this.init.currency.fromCurrency,
                  txnControlId: txnControlId,
                }
              } else if(this.buyOrSellState == 'Market') {
                 params = {
                  market: this.defaultPair, 
                  sourceAmount: parseFloat(this.sellValues.quantity).toFixed(5),
                  sourceCurrency: this.init.currency.toCurrency,
                  targetCurrency: this.init.currency.fromCurrency,
                  txnControlId: txnControlId,
                }
              }

              let submitUrl = "/cx/v2/txn/trade/sell/submit";

              if(this.defaultPair == 'BLXUSDT') {
                submitUrl = "/cx/txn/trade/sell/submit";
              }

              const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
          
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                data,
                url: submitUrl,
              };

              this.axios(options)
                .then(() => { 

                  let confirmUrl = "/cx/v2/txn/trade/sell/confirm";

                  if(this.defaultPair == 'BLXUSDT') {
                    confirmUrl = "/cx/txn/trade/sell/confirm";
                  }

                  const params = {
                    txnControlId: txnControlId,
                  }

                  const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
              
                  const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    data,
                    url: confirmUrl,
                  };

                  this.axios(options)
                    .then(() => { 
                      this.getFunds();
                      this.errors.buyOrSell = "Order Submitted!"
                      this.sellValues.quantity = ""
                      this.sellValues.orderValue = 0;
                      this.getBuyOrders('00');
                      this.getSellOrders('00');
                      this.getBuyOrders('11');
                      this.getSellOrders('11');
                      this.getRate();

                      setTimeout(() => {
                        this.errors.buyOrSell = ''
                      }, 5000)


                    }).catch((err) => {
                      
                    })
                }).catch((err) => {
                  this.errors.buyOrSell = err.response.data.msgText
                  if(err.response.data.fieldErrors) {
                    this.errors.buyOrSell = err.response.data.fieldErrors[0].defaultMessage
                  }

                  setTimeout(() => {
                    this.errors.buyOrSell = ''
                  }, 5000)
                })
            })
            .catch((error) => {
              
            });
          }
      },

      addColorSet() {
        CanvasJS.addColorSet("purpleShades",
          [//colorSet Array
            "#c906f9",
            "#9999ff"            
          ]);
      },
      toggleBuyOrSell(state) {
        this.isUpdatingPrice = false;
        this.buyOrSell = state
      },
      sliderChange(){
        this.buyValues.orderValue = this.buyValues.slider
        this.calculateBuyValue();
      },
       openDeposit: function(){
        this.showDepositModal = !this.showDepositModal

      },
      openWithdraw(){
        console.log(this.init.user.middleName)
         if(this.init.user.verificationStatus != 'VERIFIED') {
          window.location.href="/#/profile/kyc"
        } else {
        this.showWithdrawModal = !this.showWithdrawModal
        }
      },
      openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      openProfile(){
        if(this.verificationStatus == 'PENDING') {
          window.location.href="/#/profile/kyc"
        } else {
          this.showProfileModal = !this.showProfileModal;
        }
      },
      openHistory() {
        window.location.href = "/#/trade/history"
      },
      openFundHistory() {
        window.location.href = "/#/fund/history"
      },
      changePin() {
        this.showChangePinModal = !this.showChangePinModal;
        
      },
      openLogin(){
        this.showLoginModal = !this.showLoginModal;
        this.showSignupModal = false;
      },
      openSignup(){
        this.showSignupModal = !this.showSignupModal;
        this.showLoginModal = false;
      },
       onChange(event){
          this.currency = event.target.value;
      },
  
        getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.init.profile = response.data;
               this.userId = response.data.entityId

               this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                   console.log(this.init.corpDetails.corpInfo)
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress

               if(this.init.user.middleName != '' && this.init.user.verificationStatus == 'UNVERIFIED') {
                this.verificationStatus = 'PENDING'
               } else if(this.init.user.middleName != '' && this.init.user.verificationStatus == 'VERIFIED') {
                this.verificationStatus = 'VERIFIED'
               }


              })
      },
      
     checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.getProfile();
            this.getCompletedHistory();
            this.init.hasSession = true
              setTimeout( () => {
                this.showLoading = false;
              },1000)
              this.getFunds();
            }).catch((err) => {
                   this.init.hasSession = false;
              if(err.response.data.msgCode == '00001') {
                this.showLoading = false;
                window.location.href="/#/"
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      getBalance: function(currency){
        this.balance[currency] = {};
        if(this.init.balance.length > 0 ) {
          this.init.balance.forEach((value) => {
            
            if(currency == value.currency) {
              this.balance[currency] = value;
              
            }

          })

          if(Object.keys(this.balance[currency]).length === 0 && this.balance[currency].constructor === Object) {
            return parseFloat(0).toFixed(5);
          } else {

            return parseFloat(this.balance[currency].spotBalance).toFixed(5);

          }

        } else {
           return parseFloat(0).toFixed(5);
        }

        

 
      },

      getFunds: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/funds',
        };

        this.axios(options)
           .then((response) => { 

            this.init.balance = response.data;
            // console.log(this.init.balance )
              /*response.data.forEach((value) => {
                if(this.init.currency.fromCurrency == value.currency) {

                  

                  // if(this.init.balance.currentBalance == 0) {
                  //   this.init.balance.currentBalance = '1'
                  //   console.log('0')
                  // }
                }
              })*/
            }).catch((err) => {
              


            })
      },
      
        logout: function(){
            this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/#/";
                
            }).catch((err) => { 
              console.log(err)

            })
        },
       
        
      clearTimer(){
       
      },
       reloadPage(){
        window.setTimeout( function() {
            window.location.reload();
          }, 59000);
      },
    


     getBuyOrders: function(status){
           if(status == '00') {
                
                //this.pendingBuyOrders = [];
                
              }

              if(status == '11') {
                // this.completedBuyOrders = [];
                
              }
         const params = {
            status: status,
            itemPerPage: '15',
            market: this.defaultPair
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/pub/trade/buy/orders',
          };

          this.axios(options)
            .then((response) => {

              if(status == '00') {
 

                this.pendingBuyOrders = response.data;

                
              }

              if(status == '11') {
                 this.completedBuyOrders = response.data;
                
              }
 
            })
     },
     getSellOrders: function(status){
       if(status == '00') {
                //this.pendingSellOrders = [];
                
              }

              if(status == '11') {
                 //this.completedSellOrders = [];
                
              }
         const params = {
            status: status,
            itemPerPage: '15',
            market: this.defaultPair
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/pub/trade/sell/orders',
          };

          this.axios(options)
            .then((response) => {

              if(status == '00') {
                this.pendingSellOrders = response.data;

               
                
              }

              if(status == '11') {
                 this.completedSellOrders = response.data;
                
              }


               
                
            })
     },

     zeroPad: function(num, places) {
      return String(num).padStart(places, '0');
     },

     subscribe () {
      
        let pusher = new Pusher('874b675051ad83bc6d82', { cluster: 'ap3' })
        pusher.subscribe('xcoin');
        pusher.bind('refresh.data', data => {

            if(this.$route.name == 'MarketDashboard') {
              this.getRate(); 
              this.getPairs();
              this.getBuyOrders('00');
              //this.getBuyOrders('00');
              this.getSellOrders('00');
              //this.getSellOrders('00');
              this.getOrderBook();
            }
            
            
        })
      },

      changeBorderColor: function (chart){
          var dataSeries;

          
          for( var i = 0; i < chart.options.charts[0].data.length; i++){
              dataSeries = chart.options.charts[0].data[i];



              for(var j = 0; j < dataSeries.dataPoints.length; j++){
                dataSeries.dataPoints[j].color = (dataSeries.dataPoints[j].y[0] <= dataSeries.dataPoints[j].y[3]) ? (dataSeries.risingColor ? dataSeries.risingColor : dataSeries.color) : (dataSeries.fallingColor ? dataSeries.fallingColor : dataSeries.color);

              } 
          }
        },

        rangeChanged: function(e){
          console.log(e)
          var minimum = parseInt(e.minimum / 1000); //Converting milliseconds to seconds
          var maximum = parseInt(e.maximum / 1000); //Converting milliseconds to seconds

          let interval = ((maximum-minimum)/60) < 1 ? 1: (maximum-minimum)/60;

          if(e.index == 11) {

            this.renderChart(false);
          } else {

            
            let fromDate = new Date(this.stockChart.rangeSelector.inputFields.startValue)
            fromDate.setDate(fromDate.getDate() - 1);

            var d = fromDate.getDate();
            var m = fromDate.getMonth() + 1; //Month from 0 to 11
            var y = fromDate.getFullYear();

            let toDate = new Date(this.stockChart.rangeSelector.inputFields.startValue);
            toDate.setDate(toDate.getDate() + 1);
 

            var dT = toDate.getDate();
            var mT = toDate.getMonth() + 1; //Month from 0 to 11
            var yT = toDate.getFullYear();

            let fromDateStr = m + "/" + d + "/" + y;
            let toDateStr = mT + "/" + dT + "/" + yT; 

            const options = {
              method: 'GET', 
              url: '/pub/market/data/interval?market=' + this.defaultPair + "&interval=" + interval + "&fromDate=" + fromDateStr + "&toDate=" + toDateStr,
            };

            this.axios(options)
              .then((response) => {
                this.chartResponse = response.data;
                  let chartData = [];
                  let navigatorData = [];

                  response.data.forEach((el, i) => {
                    let obj = {};
                    obj.x = new Date(el.date);

                    obj.y = []; 
                    obj.y[0] = el.open;
                    obj.y[1] = el.high;
                    obj.y[2] = el.close;
                    obj.y[3] = el.low; 


                    chartData.push(obj)


 
                  })
 
                  //if(chartData.length > 0) {
                     this.stockChart.options.charts[0].data[0].dataPoints = [
      { x: new Date("2023-01-25T00:00:00"), y: [50, 70, 60, 65] },
      { x: new Date("2023-01-25T00:01:00"), y: [40, 60, 55, 55] },
      { x: new Date("2023-01-25T00:02:00"), y: [30, 40, 45, 40] }];
                    this.changeBorderColor(this.stockChart);
 
                    this.stockChart.render();
                  // } else {
                  //   this.renderChart(false);
                  // }

                 
            }) 
          }
 

          

        },

          renderChart: function(onload) {


            console.log("this.marketPair.value: " + this.marketPair.value)
            let symbol = "";

            switch(this.marketPair.value) {
              case "BTCUSDT":
                symbol = "BINANCE:BTCUSDT"
              break;

              case "ETHUSDT":
                symbol = "BINANCE:ETHUSDT"
              break;

              case "USDTUSD":
                symbol = "BINANCEUS:USDTUSD"
              break;

              case "USDTUSD":
                symbol = "BINANCEUS:USDTUSD"
              break;
              case "TRXUSDT":
                symbol = "BINANCE:TRXUSDT"
              break;
              case "CELOUSDT":
                symbol = "BINANCE:CELOUSDT"
              break;
              case "CUSDUSDT":
                symbol = "BITTREX:CUSDUSDT"
              break;
              case "EOSUSDT":
                symbol = "BINANCE:EOSUSDT"
              break;
      
            }
        
      
            console.log("symbol: " + symbol)
            if(symbol == '') {


            var datafeed = {
    
                onReady: function(cb) {

                  setTimeout(() => {
                    cb({
                    "supports_marks": false,
                    "exchanges": [
                    {
                    "name": "Coexstar PRO",
                    "value": "Coexstar",
                    "desc": "Coexstar PRO"
                    }
                    ],
                    "supports_group_request": false,
                    "supports_search": true,
                    "resolution": [
                    "1","60","180","D", "2D", "3D", "W", "3W", "M", "6M"
                    ],
                    "supports_timescale_marks": false
                    });
                  }, 0)


                    
                },
            searchSymbols: function(userInput, exchange, symbolType, onResultReadyCallback) {
                // your implementation here
                // For example, you can use an AJAX call to a server-side script that returns a list of symbols that match the user's input
            },
            resolveSymbol: function(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {

              fetch('/symbols?symbol=' + symbolName)
                .then(response => response.json())
                 .then(data => { 

                    console.log(data);
                     var symbolInfo = {
                              name: data.name,
                              description: data.description,
                              type: data.type,
                              session: data.session,
                              exchange: "COEXSTAR",
                              ticker: data.ticker,
                              minmov: data.minmov,
                              pricescale: data.pricescale,
                              timezone: data.timezone,
                              resolution: [
                    "1","60","180","D", "2D", "3D", "W", "3W", "M", "6M"
                    ],
                    has_intraday: true,
                    has_no_volume: false,

                          };

                          onSymbolResolvedCallback(symbolInfo);


                  });

                 
            },
 
            getBars: function(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
              
         
              let interval = resolution;

              if(resolution == '1D') {
                interval = 1440;
              }

              let fromDate = new Date(periodParams.to * 1000)
                  fromDate.setFullYear(fromDate.getFullYear() - 1);
                  
                  var d = fromDate.getDate();
                  var m = fromDate.getMonth() + 1; //Month from 0 to 11
                  var y = fromDate.getFullYear();

                  let toDate = new Date(periodParams.to * 1000);
                  toDate.setDate(toDate.getDate() + 2);
       

                  var dT = toDate.getDate();
                  var mT = toDate.getMonth() + 1; //Month from 0 to 11
                  var yT = toDate.getFullYear();

                  let fromDateStr = m + "/" + d + "/" + y;
                  let toDateStr = mT + "/" + dT + "/" + yT; 


                // console.log("FROM: " + new Date(periodParams.from * 1000))
                // console.log("TO: " + new Date(periodParams.to * 1000))
                // console.log("countBack: " + periodParams.countBack)
                fetch('/pub/market/data/interval?market='+ symbolInfo.ticker +'&interval='+ interval +'&fromDate=' + fromDateStr + '&toDate=' + toDateStr + "&empty=" + !periodParams.firstDataRequest)
                .then(response => response.json())
                 .then(data => {
  

                      try {
                        const bars = data.map((d) => ({
                          ...d,
                          time: (new Date(d.date).getTime()),
                          volume: 0
                        }));

 


                        if (bars?.length) {
                         if(periodParams.firstDataRequest) {
                           onHistoryCallback(bars, { noData: false });
                         }
                         
                        } else {
                          console.log('NO DATA', bars);
                          onHistoryCallback([], { noData: true });
                        }
                      } catch (error) {
                        console.log('[getBars]: methods', error);
                        onErrorCallback(error);
                      }
                         
                  }).catch((err) => {
                    console.log(err)
                    //onErrorCallback(err);

                  })




            },
            
            subscribeBars: function(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
                // your implementation here
                // For example, you can use WebSockets to receive real-time data for a specific symbol and interval
                

            },
            unsubscribeBars: function(subscriberUID) {
                // your implementation here
                // For example, you can close the WebSocket connection when the subscriber unsubscribes
            }
        };

        var widget = window.tvWidget = new TradingViewStandAlone.widget({
          "autosize": true,
          symbol: this.marketPair.value,
          interval: '1D',
          container: "tradingview",

          //  BEWARE: no trailing slash is expected in feed URL
          // datafeed: new Datafeeds.UDFCompatibleDatafeed(datafeedUrl),
          datafeed: datafeed,
          minimalDataRequest: true,
          library_path: "assets/charting_library/",
          locale:"en",

          charts_storage_url: 'https://saveload.tradingview.com',
          charts_storage_api_version: "1.1",
          client_id: 'tradingview.com',
          user_id: 'public_user_id', 
          enable_publishing:false,
          style: "1",

          "timezone": "Asia/Singapore",
          "theme": "dark", 
          "disabled_features": ['widget_logo'],
        });

      } else { 
        
        setTimeout(() => {
          new TradingView.widget(
              {
              "autosize": true,
              "symbol": symbol,
              "interval": "D",
              "timezone": "Asia/Singapore",
              "theme": "dark",
              "style": "1",
              "locale": "en",
              "toolbar_bg": "#f1f3f6",
              "enable_publishing": false,
              "container_id": "tradingview",
              "disabled_features": ['widget_logo'],

            }
              );
        }, 1000)
      } 
      


     }
      
  },
  watch: {
    'marketPair': function(newValue, oldValue) {
       
          if(newValue.value !== oldValue.value) {   
              //this.$route.params.pair  = newValue;
              console.log(this.init.hasSession);
              console.log(this.$route.name);

              if(this.init.hasSession && this.$route.name == 'MarketDashboard') {
                window.location.href = "/#/market/" + newValue.value;
              }

              
              //console.log(newValue)
              //location.reload();

              this.init.currency.fromCurrency = newValue.label.substring(newValue.label.indexOf('/') + 1)
              this.init.currency.toCurrency = newValue.label.substring(0, newValue.label.indexOf('/'))

              this.buyValues.quantity = ""
              this.buyValues.orderValue = 0;

              this.sellValues.quantity = ""
              this.sellValues.orderValue = 0;
              
             // this.getRate();
              this.getBuyOrders('11');
              //this.getBuyOrders('00');
              this.getSellOrders('11');
              //this.getSellOrders('00');
              this.getOrderBook();
              this.renderChart(true);
              this.getPairsDetails();
            }
      },
    },

  created() {

    this.subscribe();
    
   
  },

  beforeMount() {

    if(this.$route.params.pair == undefined) {
      this.defaultPair = "ETHUSDT"

      if(localStorage.getItem("pair") != null) {
        this.defaultPair = localStorage.getItem("pair");
      }
    } else {
      this.defaultPair = this.$route.params.pair;
    }

    console.log(this.defaultPair)

    this.connectWebSocket();
    this.checkSession();
    this.getPairs();
    this.getBuyOrders('00');
              //this.getBuyOrders('00');
              this.getSellOrders('00');
              //this.getSellOrders('00');
    this.getOrderBook();
    //this.getSellOrders('00'); 
  }
   
   
  
}
</script>


<style scoped lang="scss">

  .php-btn:hover{
    background: linear-gradient(to top, #695536 100%);
    color: white;
  }
  .php-btn{
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    color: white;
  }
  .active{
    background: linear-gradient(to top, #695536 100%);
    color: white !important;
  }
  .php-btn:active{
    background: linear-gradient(to top, #695536 100%);
    color: white !important;
  }
  .php-btn:focus{
    background: linear-gradient(to top, #695536 100%);
    color: white !important;
  }
  .usdt-btn:hover{
    background: linear-gradient(360deg, #000a26, #10184d);
    color: white;
  }
  .usdt-btn{
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    color: white;
  }
  .usdt-btn:active{
    background: #695536 !important;
    color: white !important;
  }
  .usdt-btn:focus{
    background: #695536 !important;
    color: white !important;
  }
.selected-option {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
  /* Add your desired styling for the selected option */
}

  .market-price {
    font-weight: bold;
    font-size: 1.25em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .justify-content-end {
    display: flex;
    justify-content: end;
  }

  #left-bars {
    font-size: 80%;
  }

  .main-container {
    justify-content: space-between;
    padding: 1em;
  }
  .left-col {
    width: 50%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap; 
        padding: 0.25em;
  }

  .right-col {
    width: 50%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap; 
        padding: 0.25em;
  }

  #tradingview {
    height: 25em;
  }
  table {
    width: 100%;
  }
.mr-5 {
  margin-right:2em;
}
.navbar {
        background-color:#F69441;
    }

    #btnLogin {
      background: #695536 !important;
        color:white;
    }

    #btnSignup {
      background: #695536 !important;
        color:white;
    }

    #bar {
      background: #695536 !important;
        padding:15px;
    }

    #bar h4 {
        color:#bfc0c1;
        font-size:12px;
        font-family: 'Medium';
    }

    #main-body {
        background-color:#0a0a0a;
        padding:50px;
        margin-left: 20px;
    }

    #main-center {
      width: 49%;
    }

    #main-left {
      width:24%;
    }

      #main-right {
      width: 24%;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius:10px;
      padding:30px;
      color:white;
      font-family: 'Medium';
      font-size: 80%;
    }
    #main-buy{
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius:10px;
      height: 350px;
      max-height: 350px;
      padding:5px;
      max-height: calc(100vh - 110px);
      overflow-y: auto;
    }
    #main-sell{
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius:10px;
      height: 350px;
      max-height: 350px;
      padding:5px;
      max-height: calc(100vh - 110px);
      overflow-y: auto;
    }


    #main-topbar {
        background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
        color:white;
        padding:10px;
        border-radius:10px;
    }

    .pair-select {
      background-color:none;
    }

    .topbar-div h1 {
        font-size:24px;
       font-family: 'Bold';
    }

    .topbar-div h4 {
        font-size:20px;
    }

    .topbar-div h4 {
        font-size:20px;
        color:#fff;
        font-family: 'Medium';
    }
.img-currency{
  width: 3rem;
}
    .topbar-div h6 {
        font-family: 'Medium';
    }

    .topbar-div .small-header {
        font-family: 'Medium';
        color:#fff;
    }

    .topbar-div .purple-text {
        color:#fff;
    }

    #left-bar-1 {
        background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
        color:#fff;
        font-family: 'Medium';
        padding:20px;
        border-radius:10px; 
    }

    #price-column, #amount-column, #total-column {
        width:30em;
    }

    #amount-column h6, #total-column h6 {
        text-align: right !important;
        justify-content: right !important;
        align-items: right !important;
    }

  
    #buy-price-column, #buy-quantity-column {
      text-align: left !important;
    }

    .buy-price-header, .buy-quantity-header, .sell-price-header, .sell-quantity-header {
      text-align: left;
     font-family: 'Bold';
    }

    .buy-price-header, .buy-price-text, .sell-price-header, .sell-price-text {
      color:#c906f9;
    }

    #main-bottom {
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      color:white;
      
      padding:20px;
      border-radius:10px;
      font-family: 'Medium';
    }
    
    .buy-or-sell {
      width:100%;
    }

    .buy-or-sell button {
      color: #FFF;  
      width:50%;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius:10px;
      
    }
    .buy-or-sell button:hover{
      border: 1px solid  #695536;
    }

    .buy-or-sell .active{
      background: #695536 !important;
      border: 1px solid  #695536;
      z-index: 1;
    }
    .btnActive{
      background: #695536 !important;
      border: 1px solid  #695536;
      border: 1px solid white;
    }

    .bottom-field {
      background-color:#000;
      color:white;
      padding:12px;
      border-radius:10px;
    }

    .field-label {
      background: #695536 !important;
    }

    .field-text {
      font-family: 'Medium';
    }

    .btn-buy {
      background: #695536 !important;
      margin-top:1em;
      width:100%;
      color:#fff;
      padding:10px;
      border-radius:10px;
      z-index: -99 !important;
    }

    .btn-buy:hover {
      background: #695536 !important;
      
      color:#fff;
      border: 0.3px solid rgb(20, 15, 15);
      z-index: -99 !important;
    }

    #main-center-top {
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      color:white; 
      padding:20px;
      border-radius:10px;
      font-family: 'Medium';
      margin-bottom:1em;
    }
    .btn-main .active{
      background: #695536 !important;
      color: white;
    }

    ::v-deep .dropdownBtn {
      color: #fff!important;
    }
   
    /*sm*/
  @media only screen and (max-width: 640px) {
   
    .main-container {
      flex-direction: column;
    }

    #main-topbar {
      margin: 1em ;
    }
    #main-left {
      width: 100%;
      order: 2;
    }
    #main-center {
      width: 100%;
      order: 1;
    }

    #main-right {
      width: 100%;
      order: 3;
    }

    #main-body {
      font-size: 90%;
      padding: 0.25em;
    }
    label {
      font-size: 90%;
    }

    #left-bar-1 {
      margin-top: 1em;
    }
    #main-right {
      margin-top: 1em;
    }
  }

  /*med*/
  @media only screen and (min-width: 641px) and (max-width: 1007px) {
     .main-container {
      flex-direction: column;
    }

    #main-topbar {
      margin: 1em ;
    }
    #main-left {
      width: 100%;
      order: 2;
    }
    #main-center {
      width: 100%;
      order: 1;
    }

    #main-right {
      width: 100%;
      order: 3;
    }

    #main-body {
      font-size: 90%;
      padding: 0.25em;
    }
    label {
      font-size: 90%;
    }

    #left-bar-1 {
      margin-top: 1em;
    }
    #main-right {
      margin-top: 1em;
    }
  }
.column {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row ;
  justify-content: space-evenly;
   /* border: 1px solid black; */
}
.row {
    margin: 0 -5px;
    display: flex !important;
    color: white !important;
}
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 5px;
  text-align: center;
  background: transparent;
    backdrop-filter:blur(20px);
	/* box-shadow:0 0 30px rgba(0,0,0,0.3); */
	/* border: 2px solid #8585e5; */
  display: flex;
  color: white !important;
  width: auto;
  margin: 10px;
  
}
.left-bars .btn-group-vertical .active{
  background-color: white;
}
@media only screen and (min-width: 1008px) {
   #main-topbar .topbar-div .web-view{
      display: none !important;
    }

   }
  
   @media only screen and (min-width: 275px) and (max-width: 1007px) {
    .btn-group .mobile{
      display: none !important;
    }
   .mobile{
    display: none !important;
   }
   #main-left{
      display: none !important;
      }
   
  }

</style>
