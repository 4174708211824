import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "./assets/global.css";
import router from "./router";
import {store} from "./store";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAxios from "vue-axios";
import numeral from "numeral/numeral";
import VueMarquee from 'vue-marquee-component'
import * as CanvasJS from './canvasjs.min';
import 'vue-awesome/icons'
import 'vue-awesome/icons/spinner'
import Icon from 'vue-awesome/components/Icon'
import VueQrcode from '@chenfengyuan/vue-qrcode'

Vue.use(VueMarquee)


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios);
Vue.use(CanvasJS);
Vue.component('v-icon', Icon)
Vue.component(VueQrcode.name, VueQrcode);
Object.defineProperty(Vue.prototype, 'numeral', { value: numeral });
Vue.filter('toPHP', function (value) {
  return `Php${value}`;
});
Vue.filter('toUSD', function (value) {
  return `US$${value}`;
});
Vue.config.productionTip = false

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");

new Vue({
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
