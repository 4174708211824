<template>
  <div class="main">
    <img :src="require('../assets/wallet-bg.png')" class="bg" />
    <viewHeader></viewHeader>
     
        <div id="main-body">
  <div class="market-content">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Price</th>
          <th>Ask</th>
          <th>Bid</th>
          <th>High</th>
          <th>Low</th>
          <th>24H %</th>
          <th>Volume</th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="isLoading">
          <td colspan="8" class="text-center">
            <div class="spinner-container"><span class="spinner"> Loading ... </span></div>
          </td>
        </tr>

       
        <tr v-if="markets && !isLoading" v-for="market in markets">
          <td>
            <div class="symbol-container">
              <img class="small-icon" :src="getIcon(market.symbol)" >
              <div class="label-icon">{{ market.symbol }} </div>
            </div>
          </td>
          <td> {{market.last}} </td>
          <td> {{market.ask}} </td>
          <td> {{market.bid }} </td>
          <td> {{market.high }}</td>
          <td> {{market.low }} </td>
          <td> {{market.percent_change_24 }} % </td>
          <td> {{market.volume }} </td> 

        </tr>

        <tr v-if="!isLoading && markets && markets.length == 0">
          <td colspan="8" class="text-center">
            No Data found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
  </div>

</template>

<script>
 import viewHeader from '../views/viewHeader.vue';

  export default {
    name: 'MarketOverviewComponent',
    components: {
      viewHeader
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
    },
    data: function() {
      return {
        markets: [],
        isLoading: true,
      }
    },
    methods: {
      formatAmount: function(num){
        return numeral(num).format('0.00000000');
      },
      getIcon(symbol) { 

        var images = require.context('../assets/', false, /\.png$/)
        return images('./' + symbol.toLowerCase() + ".png")
      },

      redirectLogin(loginId, pin, loginHost, authz) {
        const params = {
        loginHost: loginHost,
        loginId: loginId,
        pin: pin,
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/login/pin/redirect',
      };

      this.axios(options)
        .then((response) => {
         
          localStorage.setItem("authz", authz);
          localStorage.setItem("tokenId", response.data.tokenId);
         this.$router.push({name: 'MarketDashboard', params: {pair: 'BTCUSDT'}})

          this.isError = false;
        }).catch((err) => {
          console.log(err)

        })
      },
      confirmEmail(utoken){
        const params ={
          utoken: this.$route.params.utoken
        }
      

        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/emailchange/confirm',
      };

      this.axios(options)
        .then((response) => {
         
          localStorage.setItem("utoken", response.data.utoken);
         this.$router.push({name: 'MarketDashboard', params: {pair: 'BTCUSDT'}})

        }).catch((err) => {
          console.log(err)

        })

      },
      confirmMobile(utoken){
        const params ={
          utoken: this.$route.params.utoken
        }
      

        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/mobilechange/confirm',
      };

      this.axios(options)
        .then((response) => {
         
          localStorage.setItem("utoken", response.data.utoken);
         this.$router.push({name: 'MarketDashboard', params: {pair: 'BTCUSDT'}})

        }).catch((err) => {
          console.log(err)

        })

      },


      getMarket() {
        this.axios.get('/pub/store/markets')
          .then((response) => {
           this.markets = response.data;
           this.isLoading = false;
          }).catch((err) => { 
            this.isLoading = false;
          })
      }
    },
    mounted() {
      this.getMarket();

      if(this.$route.params.authorization) {
        let decodedAuthz = window.atob(this.$route.params.authorization);
        let authzArr = decodedAuthz.split(':');
        let loginId = authzArr[0];
        let pin = authzArr[1];
        let loginHost = authzArr[2];
        this.redirectLogin(loginId, pin, loginHost, this.$route.params.authorization);

      }
      if(this.$route.params.utoken){
       this.confirmEmail(this.$route.params.utoken);
       this.confirmMobile(this.$route.params.utoken);
      }

      
    },
    watch: {
      
    }
  }
</script>
 
<style scoped lang="scss">
.bg{
  width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
}
  .market-content {

    .symbol-container {
      display:flex;
      justify-content:center;
      align-items: end;
    }
    .small-icon {
      width: 25px;
    }

    .label-icon {
      margin-left: 0.5em
    }

    overflow-x:auto;
    max-height: 40em;
    width: 100%;
    border-radius: 10px;
    padding: 0;
    table {
      width: 100%;
      thead {
        th {
          color: #FFFFFF; 
          letter-spacing: 0.65px;
          padding: 1em;
          position: sticky;
          top: 0;
          background: #000a26;
          text-align: inherit;
          font-family: 'SoinSansNeue-Bold';
        }
      }

      tbody {
        background: #000A26;
        background: linear-gradient(to top, #000A26 0%, #10184D 100%);
        td {
          color: #FFFFFF;
          letter-spacing: 0.65px;
          padding: 1em;

          a {
            color: #FFFFFF;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }  
          }

          &.hash {
            a {
              color: #FFBE21;
            }
            
          }
        }
      }
    }
  }

</style>