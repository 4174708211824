<template>
  <div class="main-content">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
    <div class="header-wrapper">
      <viewHeader/>
      
    </div> 
    <div class="d-flex">
    <div class="content-wrapper">
    
      <div class="modal-body">
       
        <div class="row">
          <br>
          <div class="box" v-if="isLoading">
            <b-icon
            icon="arrow-clockwise"
            animation="spin"
            font-scale="4"
          ></b-icon
          >
        
          </div>
        </div>
        <div v-if="errors.profile.show">
          <p style="color: lightgray;">{{errors.profile.msg}}</p>
        </div>
            <fieldset>
            <div class="box2">
              <form v-on:submit.prevent="updateEmail()">
                <div>
                  <span v-if="!isSubmittingEmail && !isEditingEmail"><button class="btn btn-cancel">Update</button></span>
                  <span class="text-center" v-if="isEditingEmail"> 
                      <span v-if="!isSubmittingEmail && isEditingEmail"><button class="btn btn-display" type="submit">Save</button></span>
                      <span ><button class="btn btn-cancel" v-on:click="cancelEditEmail">Cancel</button></span>
                  </span>
                </div>
                <label for="email">Email Address:</label>
                <input type="text" class="form-control" id="email" v-model="profile.emailAddress" :class="{disabled : !isEditingEmail}"  :disabled="!isEditingEmail">
              </form>
             
            </div>
          

            <div class="box2">
              <form v-on:submit.prevent="updateMobile()">
                <div >
                  <span v-if="!isSubmittingMobile && !isEditingMobile"><button class="btn btn-cancel">Update</button></span>
                  <span class="text-center" v-if="isEditingMobile"> 
                      <span v-if="!isSubmittingMobile && isEditingMobile"><button class="btn btn-display" type="submit">Save</button></span>
                      <span ><button class="btn btn-cancel" v-on:click="cancelEditMobile">Cancel</button></span>
                  </span>
                </div>
                <fieldset>
                  <div class="form-group mobile-container">
                    <label for="loginId">Mobile Number:</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button class="btn btn-flag" type="button" v-on:click="toggleCountryList" :disabled="!isEditingMobile" >
                          <img class="default-flag" :src="require('@/assets/signup_flags/' + country + '.png' )">
                          <span class="text-white">{{ mobileCode }}</span>
                        </button>
                      </div>
                      <input type="tel" class="form-control" v-model="profile.mobileNumber" @keypress="isNumber($event)" :disabled="!isEditingMobile" :class="{disabled : !isEditingMobile}">
                    </div>
                    <div class="country-list" v-if="showCountry">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>

                </fieldset>
                
              </form>

            </div>
            </fieldset>
        


        
 <hr/>
    <form v-on:submit.prevent="updateProfile()">
      <button class="btn btn-display">
        <span v-if="!isSubmitting && !isEditingProfile && profile.status == 'UNVERIFIED'"><button class="btn btn-cancel" >Update Profile</button>  </span>
        <span v-if="profile.status == 'VERIFIED'"><button class="btn btn-cancel">Locked</button>  </span>
      </button>
      
      <fieldset>
        
        <h5 class="legend">
          Basic Info:
        </h5>
        <div class="box">
          <label for="first">First Name:</label>
          <input type="text" class="form-control" id="first" v-model="profile.firstName"  :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        <div class="box">
          
          <label for="last">Middle Name:</label>
          <input type="text" class="form-control" id="last" v-model="profile.middleName"  :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        <div class="box">
          
          <label for="last">Last Name:</label>
          <input type="text" class="form-control" id="last" v-model="profile.lastName"  :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        </fieldset>
        <br>
        <br>
        <fieldset v-if="!isEditingProfile">
          <h5 class="legend">
            Birth details:
          </h5>
        <div class="box">
          <label for="address"> Day: </label>
          <input type="number" class=" form-control" v-model="birthDate.day" max="31" placeholder="DD"  @input="validateNumberDay" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}"/>
        </div>
        <div class="box">
          <label for="address"> Month: </label>
          <input type="number" class=" form-control" v-model="birthDate.month" max="12" placeholder="MM"  @input="validateNumberMonth" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}"/>
        </div>
        <div class="box">
          <label for="address"> Year: </label>
          <input type="number" class=" form-control" v-model="birthDate.year" min="1900" max="2008" placeholder="YYYY"  @input="validateNumber" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}"/>
        </div>
      </fieldset>
      <fieldset v-if="isEditingProfile">
          <h5 class="legend">
            Birth details:
          </h5>
        <div class="box">
          <label for="address"> Day: </label>
          <select v-model="birthDate.day" @change="updateDate" class="form-select" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                      <option value="">DD</option>
                      <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
            </select>
        </div>
        <div class="box">
          <label for="address"> Month: </label>
          <select v-model="birthDate.month" @change="updateDate" class="form-select" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                      <option value="">MM</option>
                      <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                    </select>
        </div>
        <div class="box">
          <label for="address"> Year: </label>
          <select v-model="birthDate.year" @change="updateDate" class="form-select" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                    <option value="">YYYY</option>
                    <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                  </select>
         
          
        </div>
        
      </fieldset>
      <br>
      <br>
      <h5 class="legend">
        Complete Address:
      </h5>
      <fieldset>
        <div class="box">
          <label for="address">Street:</label>
          <input type="text" class="form-control" id="address" v-model="address.numberStreet" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        <div class="box">
          <label for="address">City:</label>
          <input type="text" class="form-control" id="address" v-model="address.cityTown" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        <div class="box">
          <label for="address">Province:</label>
          <input type="text" class="form-control" id="address" v-model="address.provState" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        
      </fieldset>
        <fieldset>
          <div class="box2 mobile-container">
            <label for="business">Country:</label>
            <div class="input-group mb-3">
              <!-- <div class="input-group-prepend">
                <button class="btn btn-flag" type="button" v-on:click="toggleCountryListAddress" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                  <img class="default-flag" :src="require('@/assets/signup_flags/' + acountry + '.png' )">
               
                </button>
              </div> -->
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="acountry"  v-on:click="toggleCountryListForAddress" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
            </div>
            <div class="country-list" v-if="showCountryForAddress">
              <div class="row">
                <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                  <div>
                    <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                  </div>
                  <div class="country-name">
                    <span> {{ l.country }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="box2">
          <label for="address">Zipcode:</label>
          <input type="text" class="form-control" id="address" v-model="address.zipcode" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>

      </fieldset>

      <br>
      <br>
      <h5 class="legend">Extended Profile:</h5>

      <fieldset>
        <div class="box">
          <label for="marital-status">Marital Status:</label>
              <select id="marital-status" v-model="extended.selectedMaritalStatus" class="form-select" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                    <option value="" disabled>Select</option>
                    <option value="S">Single</option>
                    <option value="M">Married</option>
                    <option value="D">Divorced</option>
                    <option value="W">Widowed</option>
              </select>
        </div>

        <div class="box">
          <label for="birth">Place of Birth:</label>
          <input type="text" class="form-control" id="birth" v-model="extended.placeOfBirth" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        
        <div class="box">
          <label for="idNumber">ID number:</label>
          <input type="text" class="form-control" id="idNumber" v-model="extended.idNumber" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        
        </div>
          <div class="box2 mobile-container">
            <label for="business">Nationality:</label>
            <div class="input-group mb-3">
              <!-- <div class="input-group-prepend">
                <button class="btn btn-flag" type="button" v-on:click="toggleCountryListForNationality" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                  <img class="default-flag" :src="require('@/assets/signup_flags/' + ncountry + '.png' )">
               
                </button>
              </div> -->
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="ncountry"  v-on:click="toggleCountryListForNationality" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
            </div>
            <div class="country-list" v-if="showCountryForNationality">
              <div class="row">
                <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
                  <div>
                    <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                  </div>
                  <div class="country-name">
                    <span> {{ l.country }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="box2">
          <div class="text-white" for="maidenName">Mother's Maiden Name:</div>
          <input type="text" placeholder="Mother's Maiden Name" class="form-control" id="maidenName" v-model="extended.maidenName" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
            
        </div>

      </fieldset>

      <br>
      <br>
      <fieldset>
        <div class="box">
          <label for="source-funds">Source of Funds:</label>
          <select id="source-funds" v-model="extended.sourceOfFunds" class="form-select" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
            <option value="" disabled>Select</option>
            <option value="selfEmployed">self-employed</option>
            <option value="salary">salary</option>
            <option value="innvestment">investment</option>
          </select>
        </div>
        <div class="box">
          <label for="address">Monthly Income:</label>
           <input type="text" class="form-control" id="work" v-model="extended.grossMonthly" disabled :class="{disabled : isEditingProfile}">
        </div>
        
        <div class="box">
          <label for="address">Occupation:</label>
          <input type="text" class="form-control" id="occupation" v-model="extended.occupation" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        <div class="box2">
          <label for="address">Employer:</label>
          <input type="text" class="form-control" id="employer" v-model="extended.employer" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>
        <div class="box2">
          <label for="address">Nature of work:</label>
          <input type="text" class="form-control" id="work" v-model="extended.natureOfWork" :disabled="!isEditingProfile" :class="{disabled : !isEditingProfile}">
        </div>

      </fieldset>

      <br>
      <br>
      
      <br>
      <div class="text-center" v-if="isEditingProfile"> 
        <span v-if="!isSubmitting && isEditingProfile"><button class="btn btn-display" type="submit">Save</button></span>
        <span ><button class="btn btn-display" v-on:click="cancelEditProfile">Cancel</button></span>
        
    </div>
    </form>

      </div>
      <hr>
      <div class="content-wrapper">
            <button class="btn btn-main" @click="viewPoid()" v-if="!isSubmitting && !isEditingProfile">View uploaded document</button>
              <div v-if="showPoid">
                <div v-if="poid.length == 0">
                  <p class="text-white">No file uploaded</p>
                </div>
                <div v-if="poid.length > 0">
                  <label>UPLOADED</label>
                  <button class="btn btn-display" 
                    v-for="(item, index) in poid"
                    :key="index"
                    :class="getClass(item)"
                    @click="viewId(item)">
                    {{ item.poidDesc }} - {{item.approvalStatus}}
                   
                  </button>
                </div>
              </div>
        
              <div class="form-group" v-if="profile.status == 'UNVERIFIED' && showPoid">
                  <button class="btn btn-main" @click="openUpload">Upload documents</button>
              </div>

              <!-- <div class="btn-group">
                <button class="btn btn-cancel" id="loginBtn" v-on:click="goBack">Home</button>
            
              </div> -->

        </div>
    </div>
  </div>
  <Upload v-if="showUpload" @close="openUpload"></Upload>
    <Exit @close="exitModal" v-if="showExitModal"/>
     <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
     <ChangePin @close="openChangePin" v-if="showChangePin"/>
     <view-id v-if="showDetails" @close="viewId()" :poidItem="poidItem" :poidKey="poidKey"></view-id>
</div>
</template>

<script>
  import moment from 'moment';
  import Upload from '@/components/modals/Upload.vue'
  import LogoutModal from '@/components/modals/LogoutModal.vue'
  import viewHeader from '../views/viewHeader.vue'
  import ChangePin from '@/components/modals/ChangePin.vue'
  import ViewId from '@/components/modals/ViewId.vue'


  export default {
      name: 'Kyc',
      components: {
        Upload,
          LogoutModal,
          viewHeader,
          ChangePin,
          ViewId
      },
      computed: {
          show() {
              return this.$store.state.show;
          },
          init() {
                return this.$store.state.init;
            },
       },
       data: function() {
      return { 
          showLogoutModal: false,
        showChangePin: false,
        selected: '',
          state: 1,
          isVerified: false,
          profile:{
              firstName:"",
              middleName:"",
              lastName:"",
              emailAddress:"",
              mobileNumber:"",
              status:''

          },
          birthdate:'',
         
          birthDate:{
              day: '',
              month: '',
              year: ''
            },
            day: '',
            month: '',
            year: '',
            days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
          isEmail: false,
          isMobile: false,
          formattedBirthdate: '',
          isUnderage: false,
          month:'',
          day:'',
          year:'',
          errorMsg:'',
          address:{
              numberStreet:'',
              cityTown:'',
              provState:'',
              zipcode:''
          },
          showCountryForNationality: false,
          showCountryForAddress: false,
          ncountry:'PH',
          acountry:'PH',
          extended:{
              placeOfBirth: "",
              occupation:"",
              maidenName:"",
              natureOfWork:"",
              sourceOfFunds:"",
              selectedMaritalStatus:"",
              employer:"",
              idNumber:"",
              homePhone: '11111',
              grossMonthly: '',
              nationality:'PH'
          },
          errors: {
          profile: {
              msg: "",
              show: false
          }
          },
          showCountry: false,
          selected: 'mobile',
          country: 'PH',
          mobileCode: '63',
          hideOrShow: "Show",
          list: {
              mobileCodes: []
          },
          isError: false,
          showUpload: false,
          showExitModal: false,
          isEditingProfile: false,
          isSubmitting: false,
          isSubmittingMobile: false,
          isEditingMobile: false,
          isEditingEmail: false,
          isSubmittingEmail: false,
          showPoid: false,
          poid:[],
          poidItem:'',
          showDetails: false,
          poidData:'',
          poidKey:'',
          isLoading: false,
      
      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
    

      },
      methods:{
        validateNumber() {
          const maxValue = 2008; // Maximum value you want to allow
            if (this.birthDate.year > maxValue) {
              this.birthDate.year = maxValue.toString(); // Set the value to the maximum
            }
          },
          validateNumberDay() {
          const maxValue = 31; // Maximum value you want to allow
            if (this.birthDate.day > maxValue) {
              this.birthDate.day = maxValue.toString(); // Set the value to the maximum
            }
          },
          validateNumberMonth() {
          const maxValue = 12; // Maximum value you want to allow
            if (this.birthDate.month > maxValue) {
              this.birthDate.month = maxValue.toString(); // Set the value to the maximum
            }
          },
          updateDate() {
      if (this.day && this.month && this.year) {
        // Do any additional validation or formatting if needed
        const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
        //console.log('Selected Date:', formattedDate);
      }
      },
        getClass(item) {
          if (item.approvalStatus === 'APPROVED') {
            if (item.poidDesc === 'validId' || item.poidDesc === 'poa' || item.poidDesc === 'selfie') {
              return 'green';
            }
          }

          return ''; // Return an empty string if no special class is required
        },
        viewId(details){
          this.poidItem = details
          this.poidKey = details.poidKey
          console.log(this.poidKey);
          
          this.showDetails = !this.showDetails
          
        },
        toggleCountryListForNationality(){
            this.showCountryForNationality = !this.showCountryForNationality;
          },
          selectCountryForNationality: function(c) {
            this.showCountryForNationality = false;
            this.ncountry = c.countryCode
           
          },
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
        openChangePin(){
          this.showChangePin = !this.showChangePin
        },
          goBack(){
            window.location.href="/#/"
        },
          formatDate: function(time){
              return new Date().toDateString();
          },
          goToVerification(){
            window.location.href="/#/verification"
          },
         
          openUpload(){
              this.showUpload = !this.showUpload
          },
          getProfile() {
            this.isLoading = true
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'blox-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/cx/profile',
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false;
              this.init.profile = response.data;
              console.log(response.data)
              this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
              this.profile.firstName = response.data.firstName
              this.profile.middleName = response.data.middleName
              this.profile.lastName = response.data.lastName
              this.profile.emailAddress = response.data.emailAddress
              this.profile.mobileNumber = response.data.mobilePhone
              this.birthdate = response.data.birthDate
              this.birthDate.day = response.data.birthDd
              this.birthDate.month = response.data.birthMm
              this.birthDate.year = response.data.birthYear
              this.extended.idNumber = response.data.identityNumber
              this.extended.placeOfBirth = response.data.placeOfBirth
              this.extended.maidenName = response.data.mothersMaidenName
              this.extended.occupation = response.data.occupation
              this.extended.employer = response.data.employer
              this.extended.natureOfWork = response.data.natureOfWork
              this.extended.sourceOfFunds = response.data.sourceOfFunds
              this.extended.selectedMaritalStatus = response.data.maritalStatus
              this.extended.grossMonthly = response.data.grossMonthlyIncome
              this.ncountry = response.data.nationality
              this.profile.status = response.data.verificationStatus


              if(response.data.verificationStatus == 'VERIFIED'){
                this.isVerified = response.data.verificationStatus
              }

              if(response.data.presentAddress != null){
                  this.address.numberStreet = response.data.presentAddress.numberStreet
                  this.address.cityTown = response.data.presentAddress.cityTown
                  this.address.provState = response.data.presentAddress.provState
                  this.address.zipcode = response.data.presentAddress.postalCode
                  this.acountry = response.data.presentAddress.country
              }
            
             
            

              
              }).catch((err) => {
                  this.init.hasSession = false;
              })
          },
          cancelEditProfile(){
              this.isEditingProfile = false;  
              window.location.reload();
          },
          

          updateProfile(){
              if(!this.isEditingProfile) {
                      this.isEditingProfile = true;
                  } else {
              this.isSubmitting = true;
                      let hasError = false;
              
              

              const params = {
                  firstName: this.profile.firstName,
                  middleName: this.profile.middleName,
                  lastName: this.profile.lastName,
                  birthYear: this.birthDate.year,
                  birthMm: this.birthDate.month,
                  birthDd: this.birthDate.day,
                  nationality: this.ncountry,
                  emailAddress: this.profile.emailAddress,
                  mobilePhone: this.profile.mobilePhone,
                  "presentAddress.numberStreet": this.address.numberStreet,
                  "presentAddress.cityTown": this.address.cityTown,
                  "presentAddress.country": this.acountry,
                  "presentAddress.postalCode": this.address.zipcode,
                  "presentAddress.provState": this.address.provState,
                  homePhone: "1111",
                  "permanentAddress.numberStreet": this.address.numberStreet,
                  "permanentAddress.cityTown": this.address.cityTown,
                  "permanentAddress.country": this.country,
                  "permanentAddress.postalCode": this.address.zipcode,
                  "permanentAddress.provState": this.address.provState,
                  placeOfBirth: this.extended.placeOfBirth,
                  occupation: this.extended.occupation,
                  natureOfWork: this.extended.natureOfWork,
                  identityNumber: this.extended.idNumber,
                  mothersMaidenName: this.extended.maidenName,
                  maritalStatus: this.extended.selectedMaritalStatus,
                  employer: this.extended.employer,
                  sourceOfFunds: this.extended.sourceOfFunds,
                  grossMonthlyIncome: this.extended.grossMonthly

              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/cx/profile/updatebasic'
                          };
                  this.axios(options)
                      .then((response) => { 
                         
                          this.isSubmitting = false;
                          this.isEditingProfile = false; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = "Profile updated successfully";
                          setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)

                  setTimeout(function() {
                          window.location.reload();
                      }, 3000)

                      }).catch((err) => { 
                        console.log(err.response.data.fieldErrors[0].defaultMessage)
                          this.isSubmitting = true;
                          this.isEditingProfile = true; 
                          if(err.response.data.fieldErrors){
                              this.errors.profile.show = true;
                              this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                              setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)
                          }


                  })
              }

          
          },
          cancelEditEmail(){
              this.isEditingEmail = false;
              window.location.reload();
          },
          updateEmail(){
              if(!this.isEditingEmail) {
                      this.isEditingEmail = true;
                  } else {
              this.isSubmittingEmail = true;
                      let hasError = false;
              const params = {
                  emailAddress: this.profile.emailAddress,
              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/cx/updateemail'
                          };
                  this.axios(options)
                      .then((response) => { 
                          this.isSubmittingEmail = false;
                          this.isEditingEmail = false; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = "We have sent you an verification link to your new email address. Please click to complete your update.";
                          setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                              window.location.reload();
                            }, 3000)

                      }).catch((err) => { 
                          this.isSubmittingEmail = true;
                          this.isEditingEmail = true; 
                          if(err.response.data.fieldErrors){
                              this.errors.profile.show = true;
                              this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                              setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                              window.location.reload();
                            }, 2000)
                          }


                  })
              }

          },
          viewPoid(){
              this.showPoid = !this.showPoid;
          },
          getPoidType(){
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/poids',
          }

          this.axios(options)
              .then((response) => {               
             this.poid = response.data
             console.log(response.data)
              }).catch((err) => {
              console.log(err);

              })

          },
          toggleCountryList: function(){
              this.showCountry = !this.showCountry;
              
              },
          
          selectCountry: function(c) {
              this.showCountry = false;
              this.country = c.countryCode;
              this.mobileCode = c.mobileCode;
          },
              
          getMobileCodes: function() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/assets/mobilecodes.json',
          }

          this.axios(options)
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();
              } else {
              return true;
              }
          },
          cancelEditMobile(){
              this.isEditingMobile = false;
              window.location.reload();
          },
          updateMobile(){
              if(!this.isEditingMobile) {
                      this.isEditingMobile = true;
                  } else {
              this.isSubmittingMobile = true;
                      let hasError = false;
              const params = {
                  mobilePhone: this.mobileCode +  this.profile.mobileNumber,
              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/cx/updatemobile'
                          };
                  this.axios(options)
                      .then((response) => { 
                          this.isSubmittingMobile = false;
                          this.isEditingMobile = false; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = "We have sent a an verification link to your new mobile number. Please click to complete your update.";
                          setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                              window.location.reload();
                            }, 3000)
                      }).catch((err) => { 
                          this.isSubmittingMobile = true;
                          this.isEditingMobile = true; 
                          if(err.response.data.fieldErrors){
                              this.errors.profile.show = true;
                              this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                              setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                              window.location.reload();
                            }, 2000)
                          }
                          


                  })
              }

          },
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'blox-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/cx/sessioncheck',
              }
        this.axios(options)
              .then((response) => {
              this.init.hasSession = true;
              this.getProfile();
              }).catch((err) => {
                  this.init.hasSession = false;

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getMobileCodes();
          this.getPoidType();
       

      }
  }
</script>
<style scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    max-width: 1000px;
    min-width: 350px !important;
    margin: 10px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.modal-body{
  margin-left: 15px;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}


   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>